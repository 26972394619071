import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { AMIButton, AMICheckbox, AMIFormElement, AMIModal, AMIPopConfirm, AMISpinner, Icon } from '../../../ui-components/UiComponents';
import PriceTable from '../../../components/price-table/PriceTable';
import { appDataActions, appDataSelector, PageStatus } from '../../../features/appDataSlice';
import { bookingActions, BookingResult, bookingSelector, ImperialMetric } from '../../../features/bookingSlice';
import { customerDetailsSelector } from '../../../features/customerSlice';
import AMISteps, { ActivePage } from '../../../steps/Steps';
import {retrieveContent, retrieveCourierInfoContent} from '../../../utilities/ContentRetrieverUtilities';
import { getCourierInformation, getCarrierTermsAndConditions } from '../../../utilities/CourierInfo';
import { calculateBookingTotalCost, getCarrierImage, getCarrierName, getUpsUrl, roundUpToNearestPointFive, selectTitleFormatter, snakeCaseConvertor } from '../../../utilities/HelperUtilities';
import {
  getTradeRoute,
  TradeRoute,
  handleB13aRules,
  extractRule, isIntraEu
} from '../../../utilities/RulesEngineUtilities';
import './ConfirmPage.scss';
import { convertErrorToSentence, getTermsAndConditions, placeBooking } from './utils';
import dayjs from 'dayjs';

const ConfirmPage = () => {

  const dispatch = useAppDispatch();
  const appData = useAppSelector(appDataSelector);
  const booking = useAppSelector(bookingSelector);
  const customer = useAppSelector(customerDetailsSelector);
  const customRules = appData.customRules;

  const collectionDetails = booking.collectionDetails;
  const dropInDetails = booking.dropInDetails;
  const shipperDetails = booking.shipperDetails;
  const consigneeDetails = booking.consigneeDetails;
  const brokerDetails = booking.brokerDetails;
  const preferences = booking.preferences;
  const selectedQuote = booking.selectedQuote;
  const total = calculateBookingTotalCost(selectedQuote, booking, customer);
  const kgLb = booking.quotes[0].weight.actual.unit === 'LB' ? 'lb' : 'kg';
  const dropInSelected = appData.dropInSelected;
  const carrier = getCarrierName(booking.selectedQuote.quoteId);

  const history = useHistory();

  const [showStartOverPopConfirm, setShowStartOverPopConfirm] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSpinning, setIsSpinning] = useState<boolean>(true);
  const [courierInfoContent, setCourierInfoContent] = useState("");

  const tradeRoute = getTradeRoute(customer, booking);
  const bookingIsIntraEU :boolean = isIntraEu(booking);
  const isWlDomainOrigin = window.location.href.includes('expressfr8');

  useEffect(() => {
    retrieveCourierInfoContent(booking, customer)
      .then((result: any) => {
        if (result.constructor !== Array) setCourierInfoContent(result)
        else setCourierInfoContent(getCourierInformation(customer, booking, booking.selectedQuote.quoteId, result).content)
        setIsSpinning(false)
      })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
    if (booking.origin.value === 'CA') {
      handleB13aRules(customer, booking, dispatch).catch(error => console.error(error));
    }
  }, [])

  const toggleModal = (event: any) => {
    event?.stopPropagation()
    setIsModalOpen(!isModalOpen);
  }

  const onEdit = (section: string) => {
    dispatch(appDataActions.setSectionToEdit(section));
    history.push('/details');
  }

  const handleCheck = (checkboxName: string) => {
    dispatch(bookingActions.updateField({
      field: checkboxName,
      value: !(booking as any)[checkboxName]
    }))
  }

  const onStartOver = () => {
    setShowStartOverPopConfirm(true);
  }

  const popConfirmTarget = document.getElementById('confirm-page-start-over-btn');

  const cancelStartOver = () => {
    setShowStartOverPopConfirm(false);
  }

  const confirmStartOver = () => {
    setShowStartOverPopConfirm(false);
    dispatch(bookingActions.resetState());
    dispatch(appDataActions.resetStateWithConditions());
    history.push('/single-booking');
  }

  const updateSteps = async() => {
    await dispatch(appDataActions.setConfirmPageStatus(PageStatus.COMPLETE));
  }

  const getHiddenFields = () => {
    const collectionCountyFieldHidden = extractRule(customRules, 'upsCollCountyStateHidden')?.value;
    const consigneeCountyFieldHidden = extractRule(customRules, 'upsCneeCountyStateHidden')?.value;

    const hiddenFields: any = {
      collectionDetails: [],
      consigneeDetails: []
    };
    if (collectionCountyFieldHidden) hiddenFields.collectionDetails.push('countyStateProvince');
    if (consigneeCountyFieldHidden) hiddenFields.consigneeDetails.push('countyStateProvince');

    return hiddenFields;
  }

  const onContinue = async() => {
    setShowErrors(true);
    setIsBooking(true);

    const hiddenFields = getHiddenFields();

    if (
      (booking.termsCheck || isWlDomainOrigin)
      && booking.dangerousGoodsCheck
      && booking.courierInfoCheck
    ) {
      const igBookingReturn: BookingResult = await placeBooking(booking, appData, customer, dispatch, hiddenFields);
      if (igBookingReturn?.shipmentId && booking.igBookingErrors.length < 1) {
        await updateSteps();
        history.push('/finish');
      }
    }

    setIsBooking(false);
  }

  useEffect(() => {
    if (appData.confirmPageStatus !== PageStatus.COMPLETE) {
      dispatch(appDataActions.setConfirmPageStatus(PageStatus.IN_PROGRESS));
    }
  }, [])

  useEffect(() => {
    dispatch(bookingActions.updateIgBookingErrors([]));
  }, []);

  const [carrierTsAndCs, setCarrierTsAndCs] = useState<any>();

  useEffect(() => {
    setCarrierTsAndCs(getCarrierTermsAndConditions(booking, booking.selectedQuote.quoteId));
  }, [booking])

  const weightUnit = booking.imperialMetric === ImperialMetric.IMPERIAL
    ? ' lb '
    : ' kg ';

  const dimensionUnit = booking.imperialMetric === ImperialMetric.IMPERIAL
    ? ' in '
    : ' cm ';

  const [aesItnText, setAesItnText] = useState<any>('AES/ITN or exemption code');

  useEffect(() => {
    retrieveContent('details-aesItn', booking.origin.value, customer)
      .then((result: any) => {
        result && setAesItnText(result)
      })
      .catch(error => console.error(error));
  }, [])

  return (
    <div className="confirm-page">
      <AMISteps activePage={ActivePage.CONFIRM}/>

      <div
        className="confirm-page__main-container default-page-grid"
        id="confirm-page__main-container"
      >
        <div className="confirm-container vertical-card">
          <p className="card-header">Confirm Booking</p>

          <div className="confirm-container__top-row">
            <div className="confirm-container__carrier-container">
              <img
                src={getCarrierImage(selectedQuote.quoteId)}
                alt="Carrier Logo"
              />

              <p className="confirm-container__service-title">{snakeCaseConvertor(selectedQuote.carrierProductCode)}</p>
            </div>

            <div className="confirm-container__to-from-container">
              <span>{booking.origin.title}</span>
              <Icon
                name="Parcel"
                color="var(--secondary)"
                style={{
                  width: '24px',
                  height: '24px'
                }}
              />
              <span>{booking.destination.title}</span>

            </div>
          </div>

          <div className="confirm-container__pieces">
            {booking.pieces.map((piece, index) => {
              return (
                <li key={index}>
                  {piece.weight + weightUnit}
                  {`${piece.length ? ' | ' + piece.length + dimensionUnit + 'x ' : ''}`}
                  {`${piece.width ? piece.width + dimensionUnit + 'x ' : ''}`}
                  {`${piece.height ? piece.height + dimensionUnit : ''}`}
                </li>
              )
            })}
          </div>

          <div className="quote-details-summary">
            <p>Total Weight: <span>{selectedQuote.weight.actual.value}{kgLb}</span></p>
            <p>Chargeable Weight: <span>{roundUpToNearestPointFive(selectedQuote.weight.chargeable.value)}{kgLb}</span></p>
          </div>

          <PriceTable
            booking={booking}
            total={total}
          />

          <div className="confirm-container__shipment-details horizontal-card">
            <p className="horizontal-card__title">Shipment Details</p>

            <AMIButton
              className="confirm-container__edit-btn"
              onClick={onEdit.bind(null, 'shipment-details')}
            >Edit</AMIButton>

            <div className="confirm-container__shipment-details__grid">
              {tradeRoute !== TradeRoute.DOMESTIC || bookingIsIntraEU && <p><span>Reason for Export:</span><br /> {selectTitleFormatter(booking.reasonForExport)}</p>}
              {tradeRoute !== TradeRoute.DOMESTIC || bookingIsIntraEU && <p><span>Country of Origin:</span><br /> {booking.countryOfOrigin.title}</p>}
              <p><span>Value:</span><br /> {
                booking.totalShipmentValue
                  ? booking.preferredCurrency.symbol + (+booking.totalShipmentValue).toFixed(2)
                  : 'Not provided'
              }</p>
              <p><span>HAWB Ref:</span><br /> {booking.hawb}</p>
              <p><span>Contents:</span><br /> {booking.contentDescription}</p>
              {tradeRoute !== TradeRoute.DOMESTIC || bookingIsIntraEU && <p><span>Freight Charge:</span><br /> {booking.preferredCurrency.symbol}{booking.freightCharge ? (+booking.freightCharge).toFixed(2) : 'N/A'}</p>}
            </div>

            {booking.insure && <div className="confirm-container__shipment-details__insured">
              <Icon
                name="ShieldCheck"
                color="var(--success)"
                style={{marginRight: '4px'}}
              />
              This delivery is insured
            </div>
            }

            {!booking.insure && <div className="confirm-container__shipment-details__not-insured">
              <Icon
                name="ShieldCross"
                color="var(--error)"
                style={{marginRight: '4px'}}
              />
              This delivery is not insured
            </div>
            }

          </div>

          {booking.selectedQuote.serviceType === "COLLECTED"
            && <div className="confirm-container__collection-details horizontal-card">
              <p className="horizontal-card__title">Collection Details</p>
              <p className="confirm-container__address-type">
                {booking.originResidential ? "Residential" : "Commercial"}
              </p>

              <AMIButton
                className="confirm-container__edit-btn"
                onClick={onEdit.bind(null, 'collection-details')}
              >Edit</AMIButton>

              <p className="primary-highlight-text">
                {collectionDetails.pickUpFrom.slice(0, 5) + ' - '}
                {collectionDetails.pickUpTo.slice(0, 5) + ' '}
                {dayjs(booking.readyDate).format("dddd, DD MMM YYYY")}
              </p>

              <div className="confirm-container__address-details-container">
                <h4>{collectionDetails.companyName}</h4>
                <p>{collectionDetails.addressLine1}</p>
                {collectionDetails.addressLine2 && <p>{collectionDetails.addressLine2}</p>}
                {collectionDetails.addressLine3 && <p>{collectionDetails.addressLine3}</p>}
                <p>{collectionDetails.cityTown}</p>
                <p>{collectionDetails.countyStateProvince}</p>
                <p>{booking.origin.value}</p>
                <p>{booking.originPostalCode}</p>
              </div>

              <p><span>Telephone:</span> {collectionDetails.telephoneNumber}</p>
              <p><span>Email:</span> {collectionDetails.email}</p>
              <p><span>Additional Information:</span> {collectionDetails.additionalInfo}</p>
            </div>
          }

          {
            booking.selectedQuote.serviceType === "DROP_IN"
            && carrier === "ups"
            && customer.customerCountryCode !== "ZA"
            && (
            <div className="confirm-container__drop-in-details horizontal-card">
              <p className="horizontal-card__title">Drop In Details</p>
              <p>Please click <a href={getUpsUrl(booking)} target="_blank" rel="noreferrer">here</a> to find your nearest drop in point</p>
            </div>
          )}

          {
            booking.selectedQuote.serviceType === "DROP_IN"
            && customer.customerCountryCode !== "ZA"
            && carrier !== "ups"
            && (
              <div className="confirm-container__drop-in-details horizontal-card">
                <p className="horizontal-card__title">Drop In Details</p>

                <AMIButton
                  className="confirm-container__edit-btn"
                  onClick={onEdit.bind(null, "drop-in-details")}
                >Edit</AMIButton>

                {dropInSelected && (
                  <>
                    <div className="confirm-container__address-details-container">
                      <h4>{dropInDetails.companyName}</h4>
                      <p>{dropInDetails.addressLine1}</p>
                      {dropInDetails.addressLine2 && <p>{dropInDetails.addressLine2}</p>}
                      {dropInDetails.addressLine3 && <p>{dropInDetails.addressLine3}</p>}
                      <p>{dropInDetails.cityTown}</p>
                      <p>{dropInDetails.countyStateProvince}</p>
                      <p>{dropInDetails.countryCode}</p>
                      <p>{dropInDetails.postalCode}</p>
                    </div>

                    <p><span>Telephone:</span> {dropInDetails.telephoneNumber}</p>
                  </>
                )}

                {!dropInSelected && (
                  <p>We are unable to find a nearby location at the moment. Please drop your shipment into your nearest AMI receiving centre.</p>
                )}

              </div>
            )
          }

          {booking.selectedQuote.serviceType === 'DROP_IN'
            && tradeRoute === TradeRoute.DOMESTIC
            && carrier !== "ups"
            && <div className="confirm-container__drop-in-details horizontal-card">
              <p className="horizontal-card__title">Sender Details</p>

              <AMIButton
                className="confirm-container__edit-btn"
                onClick={onEdit.bind(null, 'shipper-details')}
              >Edit</AMIButton>

              <div className="confirm-container__address-details-container">
                <h4>{shipperDetails.companyName}</h4>
                <p>{shipperDetails.addressLine1}</p>
                {shipperDetails.addressLine2 && <p>{shipperDetails.addressLine2}</p>}
                {shipperDetails.addressLine3 && <p>{shipperDetails.addressLine3}</p>}
                <p>{shipperDetails.cityTown}</p>
                <p>{shipperDetails.countyStateProvince}</p>
                <p>{shipperDetails.country.value}</p>
                <p>{shipperDetails.postalCode}</p>
              </div>

              <p><span>Telephone:</span> {shipperDetails.telephoneNumber}</p>
              <p><span>Email:</span> {shipperDetails.email}</p>
            </div>
          }

          {tradeRoute !== TradeRoute.DOMESTIC
            && <div className="confirm-container__drop-in-details horizontal-card">
              <p className="horizontal-card__title">Sender Details</p>

              <AMIButton
                className="confirm-container__edit-btn"
                onClick={onEdit.bind(null, 'shipper-details')}
              >Edit</AMIButton>

              <div className="confirm-container__address-details-container">
                <h4>{shipperDetails.companyName}</h4>
                <p>{shipperDetails.addressLine1}</p>
                {shipperDetails.addressLine2 && <p>{shipperDetails.addressLine2}</p>}
                {shipperDetails.addressLine3 && <p>{shipperDetails.addressLine3}</p>}
                <p>{shipperDetails.cityTown}</p>
                <p>{shipperDetails.countyStateProvince}</p>
                <p>{shipperDetails.country.value}</p>
                <p>{shipperDetails.postalCode}</p>
              </div>

              <p><span>Telephone:</span> {shipperDetails.telephoneNumber}</p>
              <p><span>Email:</span> {shipperDetails.email}</p>

              <div className="confirm-container-tax-details">
                {shipperDetails.taxNumbers[0].value
                  && (
                    shipperDetails.taxNumbers.map((element: any, index: number) => {
                      return (
                        <p key={index}><span>{element.type}:</span> {element.value}</p>
                      )
                    })
                  )}
              </div>

              {shipperDetails.exportComplianceStatement.aes && <p>{aesItnText}: {shipperDetails.exportComplianceStatement.aes}</p>}
            </div>
          }

          <div className="confirm-container__consignee-details horizontal-card">
            <p className="horizontal-card__title">Consignee Details</p>
            <p className="confirm-container__address-type">
              {booking.destinationResidential ? "Residential" : "Commercial"}
            </p>

            <AMIButton
              className="confirm-container__edit-btn"
              onClick={onEdit.bind(null, 'consignee-details')}
            >Edit</AMIButton>

            <div className="confirm-container__address-details-container">
              <h4>{consigneeDetails.companyName}</h4>
              <p>{consigneeDetails.addressLine1}</p>
              {consigneeDetails.addressLine2 && <p>{consigneeDetails.addressLine2}</p>}
              {consigneeDetails.addressLine3 && <p>{consigneeDetails.addressLine3}</p>}
              <p>{consigneeDetails.cityTown}</p>
              <p>{consigneeDetails.countyStateProvince}</p>
              <p>{booking.destination.value}</p>
              <p>{booking.destinationPostalCode}</p>
            </div>

            <p><span>Telephone:</span> {consigneeDetails.telephoneNumber}</p>
            <p><span><span>Email:</span></span> {consigneeDetails.email}</p>

            <div className="confirm-container-tax-details">
              {consigneeDetails.taxNumbers[0].value
                && (
                  consigneeDetails.taxNumbers.map((element: any, index: number) => {
                    return (
                      element.type
                      && element.value
                      && <p key={index}><span><span>{element.type}:</span></span> {element.value}</p>
                    )
                  })
                )}
            </div>
            <p style={{marginTop: '24px'}}><span>Additional Information:</span> {consigneeDetails.additionalInfo}</p>
          </div>

          {brokerDetails.brokerSelected && <div className="confirm-container__broker-details horizontal-card">
            <p className="horizontal-card__title">Broker Details</p>

            <AMIButton
              className="confirm-container__edit-btn"
              onClick={onEdit.bind(null, 'broker-details')}
            >Edit</AMIButton>

            <div className="confirm-container__address-details-container">
              <h4>{brokerDetails.companyName}</h4>
              <p>{brokerDetails.contactName}</p>
              <p>{brokerDetails.addressLine1}</p>
              {brokerDetails.addressLine2 && <p>{brokerDetails.addressLine2}</p>}
              {brokerDetails.addressLine3 && <p>{brokerDetails.addressLine3}</p>}
              <p>{brokerDetails.cityTown}</p>
              <p>{brokerDetails.countyStateProvince}</p>
              <p>{brokerDetails.country.value}</p>
              <p>{brokerDetails.postalCode}</p>
            </div>

            <p><span>Telephone:</span> {brokerDetails.telephoneNumber}</p>
            <p><span>Email:</span> {brokerDetails.email}</p>

            <div className="confirm-page-tax-details">
              {brokerDetails.taxNumbers[0].value
                && (
                  brokerDetails.taxNumbers.map((element: any, index: number) => {
                    return (
                      element.type
                      && element.value
                      && <p key={index}><span>{element.type}:</span> {element.value}</p>
                    )
                  })
                )}
            </div>
          </div>
          }

          <div className="confirm-container__preferences horizontal-card">
            <p className="horizontal-card__title">Preferences</p>

            <AMIButton
              className="confirm-container__edit-btn"
              onClick={onEdit.bind(null, 'preference-details')}
            >Edit</AMIButton>

            <div className="confirm-container__preferences__content">
              <p><span>Email Confirmation:</span><br /> {preferences.email}</p>
              <p><span>Label Type:</span><br /> {selectTitleFormatter(preferences.labelType)}</p>
            </div>
          </div>

          {!isWlDomainOrigin && (
            <AMIFormElement
              className="confirm-container__checkbox"
              errorMessage={
                showErrors
                && !booking.termsCheck
                  ? 'Please check to continue'
                  : ''
              }
            >
              <AMICheckbox
                text=""
                checked={booking.termsCheck}
                onChange={handleCheck.bind(null, 'termsCheck')}
              >
                <p>I confirm that I agree to your
                  <Link
                    to={getTermsAndConditions(customer.countryOfResidence.value)}
                    rel="noreferrer"
                    target="_blank"
                    download
                  > terms and conditions</Link>
                </p>
              </AMICheckbox>
            </AMIFormElement>
          )}

          {isWlDomainOrigin && (
            <div className="confirm-container__checkbox confirm-container__checkbox--wl-terms">
              <Icon
                name="CircleExclamation"
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "6px",
                  marginLeft: "-2px"
                }}
              />
              Terms and conditions of carriage available on request.
            </div>
          )}


          <AMIFormElement
            className="confirm-container__checkbox"
            errorMessage={
              showErrors
              && !booking.dangerousGoodsCheck
                ? 'Please check to continue'
                : ''
            }
          >
            <AMICheckbox
              text="I confirm that the shipment will not contain dangerous goods"
              checked={booking.dangerousGoodsCheck}
              onChange={handleCheck.bind(null, 'dangerousGoodsCheck')}
            />
          </AMIFormElement>

          <AMIFormElement
            className="confirm-container__checkbox"
            errorMessage={
              showErrors
              && !booking.courierInfoCheck
                ? 'Please check to continue'
                : ''
            }
          >
            <AMICheckbox
              text=""
              checked={booking.courierInfoCheck}
              onChange={handleCheck.bind(null, 'courierInfoCheck')}
            >
              <p>I confirm that I have read and understood the <span onClick={toggleModal}>important courier information</span></p>
            </AMICheckbox>
          </AMIFormElement>

        </div>

        <div className="confirm-page__button-container">
          <AMIButton
            id="confirm-page-start-over-btn"
            onClick={onStartOver}
            variant="default"
            size="large"
          >
            Start Over
          </AMIButton>

          <AMIButton
            onClick={onContinue}
            variant="primary"
            size="large"
            isSpinning={isBooking}
            style={{padding: "0 16px"}}
          >
            <Icon
              name="Parcel"
              color="var(--textLight)"
              style={{
                width: "24px",
                height: "24px",
                transform: "translateY(3px)"
              }}
            />
            Place Booking
          </AMIButton>

          <AMIPopConfirm
            parentElement={popConfirmTarget}
            isVisible={showStartOverPopConfirm}
            position="centerScreen"
            onCancel={cancelStartOver}
            onConfirm={confirmStartOver}
          >
            <p>By starting over, all previously entered data will be cleared. Are you sure you want to start over?</p>
          </AMIPopConfirm>

        </div>

        {booking.igBookingErrors.length > 0
          && <div className="confirm-page__issues-slider">
            <Icon
              name="CircleExclamation"
              color="var(--error)"
              style={{
                margin: "0 auto",
                display: "block",
                width: "52px",
                height: "52px",
                marginBottom: "24px"
              }}
            />
            <p style={{marginBottom: '8px'}}><strong>Booking Error</strong></p>

            <div className="confirm-page__issues-slider__main-content">
              {booking.bookingResult?.alerts && booking.bookingResult?.alerts?.length > 0
                && booking.bookingResult?.alerts.map((alert, index) => {
                  return (
                    <p key={index}>{alert.code}: {alert.message}</p>
                  )
                })
              }

              {booking.igBookingErrors.map((error: any, index: number) => {
                return (
                  <p
                    className="confirm-page__issues-slider__main-content__error"
                    key={index}
                  >
                    {error.code} <strong>{convertErrorToSentence(error.fieldName)}</strong>: {error.message}
                  </p>
                )
              })}
            </div>
          </div>
        }
      </div>

      {isModalOpen && <AMIModal
        title="Carrier Information"
        className='courier-modal'
        width="80%"
        close={() => toggleModal(event)}
      >
        <img
          src={getCarrierImage(booking.selectedQuote.quoteId)}
          alt="Logo"
        />
        {isSpinning && <AMISpinner className="faq-content-spinner"/>}
        {!isSpinning && (
          <div>
            <div dangerouslySetInnerHTML={{__html: courierInfoContent}}/>
            {carrierTsAndCs && (
              <p>For more information, please check our
                <Link
                  to={carrierTsAndCs}
                  rel="noreferrer"
                  target="_blank"
                  download
                > terms and conditions</Link>
              </p>
            )}
          </div>
        )}

      </AMIModal>}
    </div>
  )
}

export default ConfirmPage;