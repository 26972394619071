import React, { useEffect, useState } from 'react';
import './AccountAddresses.scss';
import { AMIAlert, AMIInput } from '../../../ui-components/UiComponents';
import { customerDetailsSelector } from '../../../features/customerSlice';
import { useAppSelector } from '../../../app/hooks';
import AddressTableLine from './address-line/AddressTableLine';
import AddEditAddressModal from './add-edit-address-modal/AddEditAddressModal';

const AccountAddresses = () => {

  const customer = useAppSelector(customerDetailsSelector);
  const addresses = customer.savedAddresses;

  const [filteredAddresses, setFilteredAddresses] = useState(addresses);
  const [showAddressModal, setShowAddressModal] = useState(false);

  useEffect(() => {
    setFilteredAddresses(addresses);
  }, [addresses])

  const onFilter = (searchString: string) => {
    const query = searchString.toLowerCase()
    const filteredResult = addresses.filter((savedAddress: any) => {
      const {address, contactDetails} = savedAddress.userDetails;
      return (
        address.organisation.toLowerCase().includes(query)
        || contactDetails.name.toLowerCase().includes(query)
        || address.streetLines[0].toLowerCase().includes(query)
        || address.streetLines[1]?.toLowerCase().includes(query)
        || address.streetLines[2]?.toLowerCase().includes(query)
        || address.city.toLowerCase().includes(query)
        || address.postalCode.toLowerCase().includes(query)
        || address.countryCode.toLowerCase().includes(query)
      )
    })
    setFilteredAddresses(filteredResult);
  }

  return (
    <div className="account-addresses">
      <h2>Addresses</h2>

      {!customer.creditCheck.branchId && (
        <AMIAlert variant="warning">
          <p>Please contact <a href="/contact" target="_blank" rel="noreferrer">customer services</a> to enable address book features.</p>
        </AMIAlert>
      )}

      {customer.creditCheck.branchId && (
        <>
          <div className="account-addresses__top-row">
            <AMIInput
              name="account-address-filter"
              placeholder="Filter..."
              onChange={(event) => onFilter(event.target.value)}
            />
            <div
              className="account-addresses__top-row__add-new-address-btn"
              onClick={() => setShowAddressModal(true)}
            >
              + Add Address
            </div>
          </div>

          <div className="account-addresses__table">
            {filteredAddresses && filteredAddresses.map((address: any, index: number) => {
              return (
                <div className="address-wrapper" key={index}>
                  <AddressTableLine
                    savedAddress={address}
                    index={index}
                  />
                </div>
              )
            })}
          </div>

          {showAddressModal && <AddEditAddressModal close={() => setShowAddressModal(false)}/>}

        </>
      )}
    </div>
  )
}

export default AccountAddresses;