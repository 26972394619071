import React from 'react';
import AMIButton from '../button/Button';
import Icon from '../icon/Icon';
import './Modal.scss';

const AMIModal: React.FC<{
  title: string,
  width?: string,
  className?: string,
  id?: string,
  close: () => void,
  buttons?: any,
}> = ({
  title,
  close,
  buttons = [
    <AMIButton
      key={Math.random()}
      variant='default'
      size="large"
      onClick={close}
    >Close
    </AMIButton>
  ],
  width = '400px',
  className,
  id,
  children
}) => {

  const handleClose = () => {
    close();
  };

  return (
    <>
      <div
        className="modal-mask"
        onClick={handleClose}
      ></div>
      <div
        className={className + ' modal'}
        id={id}
        style={{width: width}}
      >
        <div className="modal__header">
          <div className="modal__title">{title}</div>
          <div
            id="modal__close"
            className="modal__close"
            onClick={handleClose}
          >
            <Icon
              name="Close"
              style={{width: '24px', height: '24px'}}
            />
          </div>

        </div>

        <div className="modal__content">{children}</div>

        <div className="modal__footer">{buttons.map((btn: any) => {
          return {
            ...btn,
            key: btn
          }})}
        </div>
      </div>
    </>
  )
};

export default AMIModal;