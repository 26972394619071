export const bookingData = `{
  "booking": {
    "headers": [
      {
        "title": "party-id",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          }
        ],
        "descriptions": [
          "A unique value assigned to each customer account.",
          "Rates returned are as per the agreed terms with AMI."
        ]
      },
      {
        "title": "client-id",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          }
        ],
        "descriptions": [
          "The client ID provided when registering for the API."
        ]
      },
      {
        "title": "authorization",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string(bearer-token)"
          },
          {
            "title": "Allowed Values",
            "content": "Bearer <generated access token>"
          }
        ],
        "descriptions": [
          "The client ID provided when registering for the API."
        ]
      },
      {
        "title": "Content-Type",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "application/json"
          }
        ],
        "descriptions": []
      }
    ],
    "body": [
      {
        "title": "quoteId",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          },
          {
            "title": "Format",
            "content": "<carrier>-uniqueUUID"
          }
        ],
        "descriptions": [
          "The quote ID is the unique reference that represents the quote selected at the quote stage.",
          "This must be populated from the quote response. Quotes will expire 24 hours from the time it was generated.",
          "The Quote ID provided on the create shipment request can only be used once."
        ]
      },
      {
        "title": "bookingConfirmationEmail",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          },
          {
            "title": "Format",
            "content": "<>@<>.<>"
          },
          {
            "title": "Length",
            "content": "max 50"
          }
        ],
        "descriptions": [
          "The email address you require the booking confirmation email to go to.",
          "Please fill this out if your require booking confirmation emails or tracking notifications related to the shipment."
        ]
      },
      {
        "title": "customerCloseTime",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          },
          {
            "title": "Format",
            "content": "HH:MM"
          }
        ],
        "descriptions": [
          "The time inserted is the latest available pick up time at collection point.",
          "This time is on the date of collection.",
          "This is required for collected shipments."
        ]
      },
      {
        "title": "shipmentDateTime",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          },
          {
            "title": "Format",
            "content": "yyyy-MM-dd'T'HH:mm:ssX"
          }
        ],
        "descriptions": [
          "The date the shipment will be collected or is expected to be dropped in.",
          "This will be copied across from the quote request. Client will not need to provide."
        ]
      },
      {
        "title": "unitOfMeasurement",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "enum"
          },
          {
            "title": "Allowed Values",
            "content": "METRIC or IMPERIAL"
          }
        ],
        "descriptions": [
          "Unit of measurement for all weight and dimension values.",
          "METRIC kilogram (KG) and centimeters (CM)",
          "IMPERIAL pounds (LB) and inches (IN)"
        ]
      },
      {
        "title": "unitOfCurrency",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          }
        ],
        "descriptions": [
          "Unit of currency for all monetary values."
        ],
        "link": {
          "title": "Currencies",
          "url": "/api/reference-data#currencies"
        }
      },
      {
        "title": "senderDetails",
        "required": "YES",
        "criteria": [
            {
            "title": "Type",
            "content": "object"
          }
        ],
        "descriptions": [
          "Container for Sender Details"
        ],
        "subRows": [
          {
            "title": "address",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Container for Sender Address Details"
            ],
            "copyRoute": "senderDetails.address",
            "subRows": [
              {
                "title": "isCollectionAddress",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "boolean"
                  }
                ],
                "descriptions": [
                  "This is required for collected shipments to determine if the collection address is the same as the senders address."
                ],
                "copyRoute": "senderDetails.address.isCollectionAddress"
              },
              {
                "title": "organisation",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "3"
                  },
                  {
                    "title": "Max Length",
                    "content": "35"
                  }
                ],
                "descriptions": [
                  "The senders organization name."
                ],
                "copyRoute": "senderDetails.address.organisation"
              },
              {
                "title": "streetLines",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "array<string>"
                  },
                  {
                    "title": "Min Occurrence",
                    "content": "1"
                  },
                  {
                    "title": "Max Occurrence",
                    "content": "3"
                  },
                  {
                    "title": "Max Length",
                    "content": "3 per line"
                  },
                  {
                    "title": "Max Length",
                    "content": "35 per line"
                  }
                ],
                "descriptions": [
                  "The street address of the send details"
                ],
                "copyRoute": "senderDetails.address.streetLines"
              },
              {
                "title": "stateOrCounty",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "2"
                  },
                  {
                    "title": "Max Length",
                    "content": "2"
                  }
                ],
                "descriptions": [
                  "The state or county code of the sender details."
                ],
                "copyRoute": "senderDetails.address.stateOrCounty"
              },
              {
                "title": "countryCode",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "ISO 3166-1 alpha-2"
                  }
                ],
                "descriptions": [
                  "Sender country or territory code."
                ],
                "link": {
                  "title": "Country Codes",
                  "url": "/api/reference-data#country-codes"
                },
                "copyRoute": "senderDetails.address.countryCode"
              },
              {
                "title": "postalCode",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "Refer to postal code reference"
                  },
                  {
                    "title": "Required*",
                    "content": "Conditional. Refer to postal code reference"
                  }
                ],
                "descriptions": [
                  "Required if country or territory utilizes postal codes.",
                  "Note: If client provides a postal code and its a non-postal code country, then ignore and do not map. Or provide null, depending on carrier."
                ],
                "copyRoute": "senderDetails.address.postalCode"
              },
              {
                "title": "city",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "1"
                  },
                  {
                    "title": "Max Length",
                    "content": "30"
                  }
                ],
                "descriptions": [
                  "Sender city"
                ],
                "copyRoute": "senderDetails.address.city"
              },
              {
                "title": "residential",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "boolean"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "true or false"
                  }
                ],
                "descriptions": [
                  "To indicate if the origin is a residential location."
                ],
                "copyRoute": "senderDetails.address.residential"
              }
            ]
          },
          {
            "title": "contactDetails",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Container for Sender Contact Details"
            ],
            "copyRoute": "senderDetails.contactDetails",
            "subRows": [
              {
                "title": "name",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "3"
                  },
                  {
                    "title": "Max Length",
                    "content": "35"
                  }
                ],
                "descriptions": [
                  "Contact name for sender."
                ],
                "copyRoute": "senderDetails.contactDetails.name"
              },
              {
                "title": "telephone",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "10"
                  },
                  {
                    "title": "Max Length",
                    "content": "15"
                  }
                ],
                "descriptions": [
                  "Contact telephone number for for sender."
                ],
                "copyRoute": "senderDetails.contactDetails.telephone"
              }
            ]
          },
          {
            "title": "notificationDetails",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Container for Sender Notification Details"
            ],
            "copyRoute": "senderDetails.notificationDetails",
            "subRows": [
              {
                "title": "email",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "<>@<>.<>"
                  },
                  {
                    "title": "Max Length",
                    "content": "50"
                  }
                ],
                "descriptions": [
                  "The senders contact email address."
                ],
                "copyRoute": "senderDetails.notificationDetails.email"
              },
              {
                "title": "mobile",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "10"
                  },
                  {
                    "title": "Max Length",
                    "content": "15"
                  }
                ],
                "descriptions": [
                  "The senders contact telephone number."
                ],
                "copyRoute": "senderDetails.notificationDetails.mobile"
              }
            ]
          }
        ]
      },
      {
        "title": "deliveryDetails",
        "required": "YES",
        "criteria": [
            {
            "title": "Type",
            "content": "object"
          }
        ],
        "descriptions": [
          "Container for Delivery Details"
        ],
        "subRows": [
          {
            "title": "address",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Container for Delivery Address Details"
            ],
            "copyRoute": "deliveryDetails.address",
            "subRows": [
              {
                "title": "organisation",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "3"
                  },
                  {
                    "title": "Max Length",
                    "content": "35"
                  }
                ],
                "descriptions": [
                  "The organisation name of delivery address"
                ],
                "copyRoute": "deliveryDetails.address.organisation"
              },
              {
                "title": "streetLines",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "array<string>"
                  },
                  {
                    "title": "Min Occurrence",
                    "content": "1"
                  },
                  {
                    "title": "Max Occurrence",
                    "content": "3"
                  },
                  {
                    "title": "Max Length",
                    "content": "3 per line"
                  },
                  {
                    "title": "Max Length",
                    "content": "35 per line"
                  }
                ],
                "descriptions": [
                  "The street address of the delivery details"
                ],
                "copyRoute": "deliveryDetails.address.streetLines"
              },
              {
                "title": "stateOrCounty",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "2"
                  },
                  {
                    "title": "Max Length",
                    "content": "2"
                  }
                ],
                "descriptions": [
                  "The state or county code of the delivery details."
                ],
                "copyRoute": "deliveryDetails.address.stateOrCounty"
              },
              {
                "title": "countryCode",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "ISO 3166-1 alpha-2"
                  }
                ],
                "descriptions": [
                  "Delivery country or territory code. Refer to the Supported Country or Territory Tables located in the Appendix.",
                  "This must match the country code provided in the quote request."
                ],
                "link": {
                  "title": "Country Codes",
                  "url": "/api/reference-data#country-codes"
                },
                "copyRoute": "deliveryDetails.address.countryCode"
              },
              {
                "title": "postalCode",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "Refer to postal code reference"
                  },
                  {
                    "title": "Required*",
                    "content": "Conditional. Refer to postal code reference"
                  }
                ],
                "descriptions": [
                  "Required if country or territory utilizes postal codes.",
                  "Note: If client provides a postal code and its a non-postal code country, then ignore and do not map. Or provide null, depending on carrier."
                ],
                "copyRoute": "deliveryDetails.address.postalCode"
              },
              {
                "title": "city",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "1"
                  },
                  {
                    "title": "Max Length",
                    "content": "30"
                  }
                ],
                "descriptions": [
                  "Destination city",
                  "This must match the city provided in the quote request."
                ],
                "copyRoute": "deliveryDetails.address.city"
              },
              {
                "title": "residential",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "boolean"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "true or false"
                  }
                ],
                "descriptions": [
                  "To indicate if the destination is a residential location.",
                  "This should match what has been provided on the delivery Residential Indicator on the quote request."
                ],
                "copyRoute": "deliveryDetails.address.residential"
              }
            ]
          },
          {
            "title": "contactDetails",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Container for Delivery Contact Details"
            ],
            "copyRoute": "deliveryDetails.contactDetails",
            "subRows": [
              {
                "title": "name",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "3"
                  },
                  {
                    "title": "Max Length",
                    "content": "35"
                  }
                ],
                "descriptions": [
                  "Contact name for delivery."
                ],
                "copyRoute": "deliveryDetails.contactDetails.name"
              },
              {
                "title": "telephone",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "10"
                  },
                  {
                    "title": "Max Length",
                    "content": "15"
                  }
                ],
                "descriptions": [
                  "Contact telephone number for for delivery."
                ],
                "copyRoute": "deliveryDetails.contactDetails.telephone"
              }
            ]
          },
          {
            "title": "notificationDetails",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Container for Delivery Notification Details"
            ],
            "copyRoute": "deliveryDetails.notificationDetails",
            "subRows": [
              {
                "title": "email",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "<>@<>.<>"
                  },
                  {
                    "title": "Max Length",
                    "content": "50"
                  }
                ],
                "descriptions": [
                  "The delivery contact email address."
                ],
                "copyRoute": "deliveryDetails.notificationDetails.email"
              },
              {
                "title": "mobile",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "10"
                  },
                  {
                    "title": "Max Length",
                    "content": "15"
                  }
                ],
                "descriptions": [
                  "The delivery contact telephone number."
                ],
                "copyRoute": "deliveryDetails.notificationDetails.mobile"
              }
            ]
          }
        ]
      },
      {
        "title": "collectionDetails",
        "required": "CONDITIONAL",
        "criteria": [
            {
            "title": "Type",
            "content": "object"
          }
        ],
        "descriptions": [
          "This object is mandatory when sender details is not the collected address determined by isCollectionAddress flag in sender details object."
        ],
        "subRows": [
          {
            "title": "address",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Container for Collection Address Details"
            ],
            "copyRoute": "collectionDetails.address",
            "subRows": [
              {
                "title": "organisation",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "3"
                  },
                  {
                    "title": "Max Length",
                    "content": "35"
                  }
                ],
                "descriptions": [
                  "The organisation name of collection address"
                ],
                "copyRoute": "collectionDetails.address.organisation"
              },
              {
                "title": "streetLines",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "array<string>"
                  },
                  {
                    "title": "Min Occurrence",
                    "content": "1"
                  },
                  {
                    "title": "Max Occurrence",
                    "content": "3"
                  },
                  {
                    "title": "Max Length",
                    "content": "3 per line"
                  },
                  {
                    "title": "Max Length",
                    "content": "35 per line"
                  }
                ],
                "descriptions": [
                  "The street address of the collection details"
                ],
                "copyRoute": "collectionDetails.address.streetLines"
              },
              {
                "title": "stateOrCounty",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "2"
                  },
                  {
                    "title": "Max Length",
                    "content": "2"
                  }
                ],
                "descriptions": [
                  "The state or county code of the collection details."
                ],
                "copyRoute": "collectionDetails.address.stateOrCounty"
              },
              {
                "title": "countryCode",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "ISO 3166-1 alpha-2"
                  }
                ],
                "descriptions": [
                  "Collection country or territory code. Refer to the Supported Country or Territory Tables located in the Appendix.",
                  "This must match the country code provided in the quote request."
                ],
                "link": {
                  "title": "Country Codes",
                  "url": "/api/reference-data#country-codes"
                },
                "copyRoute": "collectionDetails.address.countryCode"
              },
              {
                "title": "postalCode",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "Refer to postal code reference"
                  },
                  {
                    "title": "Required*",
                    "content": "Conditional. Refer to postal code reference"
                  }
                ],
                "descriptions": [
                  "Required if country or territory utilizes postal codes.",
                  "Note: If client provides a postal code and its a non-postal code country, then ignore and do not map. Or provide null, depending on carrier."
                ],
                "copyRoute": "collectionDetails.address.postalCode"
              },
              {
                "title": "city",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "1"
                  },
                  {
                    "title": "Max Length",
                    "content": "30"
                  }
                ],
                "descriptions": [
                  "Origin city",
                  "This must match the city provided in the quote request."
                ],
                "copyRoute": "collectionDetails.address.city"
              },
              {
                "title": "residential",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "boolean"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "true or false"
                  }
                ],
                "descriptions": [
                  "To indicate if the origin is a residential location.",
                  "This should match what has been provided on the origin Residential Indicator on the quote request."
                ],
                "copyRoute": "collectionDetails.address.residential"
              }
            ]
          },
          {
            "title": "contactDetails",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Container for Collection Contact Details"
            ],
            "copyRoute": "collectionDetails.contactDetails",
            "subRows": [
              {
                "title": "name",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "3"
                  },
                  {
                    "title": "Max Length",
                    "content": "35"
                  }
                ],
                "descriptions": [
                  "Contact name for collection."
                ],
                "copyRoute": "collectionDetails.contactDetails.name"
              },
              {
                "title": "telephone",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "10"
                  },
                  {
                    "title": "Max Length",
                    "content": "15"
                  }
                ],
                "descriptions": [
                  "Contact telephone number for for collection."
                ],
                "copyRoute": "collectionDetails.contactDetails.telephone"
              }
            ]
          },
          {
            "title": "notificationDetails",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Container for Collection Notification Details"
            ],
            "copyRoute": "collectionDetails.notificationDetails",
            "subRows": [
              {
                "title": "email",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "<>@<>.<>"
                  },
                  {
                    "title": "Max Length",
                    "content": "50"
                  }
                ],
                "descriptions": [
                  "The collection contact email address."
                ],
                "copyRoute": "collectionDetails.notificationDetails.email"
              },
              {
                "title": "mobile",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "10"
                  },
                  {
                    "title": "Max Length",
                    "content": "15"
                  }
                ],
                "descriptions": [
                  "The collection contact telephone number."
                ],
                "copyRoute": "collectionDetails.notificationDetails.mobile"
              }
            ]
          }
        ]
      },
      {
        "title": "customsDetails",
        "required": "CONDITIONAL",
        "criteria": [
          {
            "title": "Type",
            "content": "object"
          }
        ],
        "descriptions": [
          "Container for Customs Details",
          "Required for international shipments."
        ],
        "subRows": [
          {
            "title": "exportComplianceStatement",
            "required": "CONDITIONAL",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              },
              {
                "title": "Allowed Values",
                "content": "AESXyyyymmddnnnnnn or NO EEI 30.37(a)"
              },
              {
                "title": "EDN Validation",
                "content": "Alphanumeric. Max 30."
              },
              {
                "title": "CCN Validation",
                "content": "Alphanumeric. Min 3. Max 30."
              },
              {
                "title": "EC Validation",
                "content": "8 numbers"
              }
            ],
            "descriptions": [
              "Field should be required when:",
              "EDN (Australia) customs value greater than 2000 AUD.",
              "Customer Customs Number ) (New Zealand) customs value greater than 1000 NZD.",
              "AES / ITN or EEI reference  (USA) customs value greater than 2500 USD."
            ],
            "copyRoute": "customsDetails.exportComplianceStatement"
          },
          {
            "title": "statementType",
            "required": "CONDITIONAL",
            "criteria": [
              {
                "title": "Type",
                "content": "enum"
              },
              {
                "title": "Allowed Values",
                "content": "DEPARTMENT_OF_STATE, DEPARTMENT_OF_COMMERCE, DEPARTMENT_OF_COMMERCE_AND_DEPARTMENT_OF_STATE"
              }
            ],
            "descriptions": [
              "For US exports only.",
              "Only if AES / ITN is supplied in Export Compliance Statement field."
            ],
            "copyRoute": "customsDetails.statementType"
          },
          {
            "title": "value",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "double"
              },
              {
                "title": "Allowed Values",
                "content": "> 0 to two decimal places"
              }
            ],
            "descriptions": [
              "The total customs value of the shipment.",
              "This must match what the client has put in on the quote request."
            ],
            "copyRoute": "customsDetails.declaredValue"
          },
          {
            "title": "invoiceDetails",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Container for invoice details."
            ],
            "copyRoute": "customsDetails.invoiceDetails",
            "subRows": [
              {
                "title": "declarationStatement",
                "required": "NO",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "3"
                  },
                  {
                    "title": "Max Length",
                    "content": "255"
                  }
                ],
                "descriptions": [
                  "This is the declaration statement which will populate the Commercial ( or Pro Forma)."
                ],
                "copyRoute": "customsDetails.invoiceDetails.declarationStatement"
              },
              {
                "title": "termsOfSale",
                "required": "CONDITIONAL",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "enum"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "DDP, DAP"
                  }
                ],
                "descriptions": [
                  "DDP: Delivered Duties Paid. Charge will be added to your invoice.",
                  "DAP: Delivered At Place. Duties and taxes paid by Consignee.",
                  "Required for all Non Docs international and cross-trade shipments."
                ],
                "copyRoute": "customsDetails.invoiceDetails.termsOfSale"
              },
              {
                "title": "taxDetails",
                "required": "CONDITIONAL",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "array"
                  }
                ],
                "descriptions": [
                  "All fields are mandatory when supplying tax details."
                ],
                "copyRoute": "customsDetails.invoiceDetails.taxDetails",
                "subRows": [
                  {
                    "title": "type",
                    "required": "YES",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      },
                      {
                        "title": "Allowed Values",
                        "content": "EORI, VAT, US_FED_TAX, US_STATE_TAX, REX, DRIV_LIC, NAT_ID, PASSPORT, MANF_ID, IOSS, OSEAS_REGD, FOREIGN_UK_VAT, AUS_GST, ECOMM_VAT, FTZ, EMP_ID_NO, GST, SSN, DUNS, BR_CNPJ, BR_CPF, BR_IE, BR_RG, BR_PASSPORT, DAN"
                      }
                    ],
                    "descriptions": [
                      "The Tax ID Type",
                      "All Tax Detail fields must be completed if one field is populated."
                    ],
                    "copyRoute": "customsDetails.invoiceDetails.taxDetails.type"
                  },
                  {
                    "title": "party",
                    "required": "YES",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      },
                      {
                        "title": "Allowed Values",
                        "content": "SENDER, RECIPIENT, THIRD_PARTY"
                      }
                    ],
                    "descriptions": [
                      "Party responsible for the tax charges and processing.",
                      "All Tax Detail fields must be completed if one field is populated."
                    ],
                    "copyRoute": "customsDetails.invoiceDetails.taxDetails.party"
                  },
                  {
                    "title": "value",
                    "required": "YES",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      },
                      {
                        "title": "Min Length",
                        "content": "3"
                      },
                      {
                        "title": "Max Length",
                        "content": "30"
                      }
                    ],
                    "descriptions": [
                      "Tax detail value. (e.g. Passport Number, EORI number)",
                      "All Tax Detail fields must be completed if one field is populated."
                    ],
                    "copyRoute": "customsDetails.invoiceDetails.taxDetails.value"
                  }
                ]
              },
              {
                "title": "exportReason",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "GIFT, PERSONAL_EFFECTS, REPAIR_AND_RETURN, SAMPLE, SOLD, NOT_SOLD"
                  }
                ],
                "descriptions": [
                  "The reason for the export.",
                  "Required only for international shipments."
                ],
                "copyRoute": "customsDetails.invoiceDetails.exportReason"
              },
              {
                "title": "specialInstructions",
                "required": "NO",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "3"
                  },
                  {
                    "title": "Max Length",
                    "content": "50"
                  }
                ],
                "descriptions": [
                  "Any special instructions for your shipment."
                ],
                "copyRoute": "customsDetails.invoiceDetails.specialInstructions"
              }
            ]
          },
          {
            "title": "importerOfRecordDetails",
            "required": "NO",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Import of record is the entity that will deal with you customs clearance. For example shipping to one location, but the accounts team is in another location."
            ],
            "copyRoute": "customsDetails.invoiceDetails.importerOfRecordDetails",
            "subRows": [
              {
                "title": "deliveryAddress",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "boolean"
                  }
                ],
                "descriptions": [
                  "When this is set to true the delivery address will be populated in the import of record details fields.",
                  "If false then import of record details must be provided."
                ],
                "copyRoute": "customsDetails.invoiceDetails.importerOfRecordDetails.deliveryAddress"
              },
              {
                "title": "contactDetails",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "object"
                  }
                ],
                "descriptions": [
                  "Container for Importer of Record Details Contact Details."
                ],
                "copyRoute": "customsDetails.invoiceDetails.importerOfRecordDetails.contactDetails",
                "subRows": [
                  {
                    "title": "name",
                    "required": "YES",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      },
                      {
                        "title": "Min Length",
                        "content": "3"
                      },
                      {
                        "title": "Max Length",
                        "content": "30"
                      }
                    ],
                    "descriptions": [
                      "Contact name for import of record."
                    ],
                    "copyRoute": "customsDetails.invoiceDetails.importerOfRecordDetails.contactDetails.name"
                  },
                  {
                    "title": "telephone",
                    "required": "YES",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      },
                      {
                        "title": "Min Length",
                        "content": "10"
                      },
                      {
                        "title": "Max Length",
                        "content": "15"
                      }
                    ],
                    "descriptions": [
                      "Telephone for import of record."
                    ],
                    "copyRoute": "customsDetails.invoiceDetails.importerOfRecordDetails.contactDetails.telephone"
                  }
                ]
              },
              {
                "title": "address",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "object"
                  }
                ],
                "descriptions": [
                  "Container for Importer of Record Details Address."
                ],
                "copyRoute": "customsDetails.invoiceDetails.importerOfRecordDetails.address",
                "subRows": [
                  {
                    "title": "organisation",
                    "required": "YES",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      },
                      {
                        "title": "Min Length",
                        "content": "3"
                      },
                      {
                        "title": "Max Length",
                        "content": "30"
                      }
                    ],
                    "descriptions": [
                      "Organisation for import of record."
                    ],
                    "copyRoute": "customsDetails.invoiceDetails.importerOfRecordDetails.address.organisation"
                  },
                  {
                    "title": "countryCode",
                    "required": "YES",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      }
                    ],
                    "descriptions": [
                      "Country Code for import of record.",
                      "This must match the delivery country code on the quote request."
                    ],
                    "link": {
                      "title": "Country Codes",
                      "url": "/api/reference-data#country-codes"
                    },
                    "copyRoute": "customsDetails.invoiceDetails.importerOfRecordDetails.address.countryCode"
                  },
                  {
                    "title": "postalCode",
                    "required": "CONDITIONAL",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      }
                    ],
                    "descriptions": [
                      "Postal Code for import of record."
                    ],
                    "copyRoute": "customsDetails.invoiceDetails.importerOfRecordDetails.postalCode"
                  },
                  {
                    "title": "streetLines",
                    "required": "YES",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "array<string>"
                      },
                      {
                        "title": "Max Length",
                        "content": "35 per line"
                      }
                    ],
                    "descriptions": [
                      "Street lines for import of record."
                    ],
                    "copyRoute": "customsDetails.invoiceDetails.importerOfRecordDetails.address.streetLines"
                  },
                  {
                    "title": "town",
                    "required": "YES",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      },
                      {
                        "title": "Min Length",
                        "content": "1"
                      },
                      {
                        "title": "Max Length",
                        "content": "30"
                      }
                    ],
                    "descriptions": [
                      "City or town for import of record."
                    ],
                    "copyRoute": "customsDetails.invoiceDetails.importerOfRecordDetails.address.town"
                  },
                  {
                    "title": "county",
                    "required": "CONDITIONAL",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      }
                    ],
                    "descriptions": [
                      "County for import of record."
                    ],
                    "copyRoute": "customsDetails.invoiceDetails.importerOfRecordDetails.address.county"
                  }
                ]
              }
            ]
          },
          {
            "title": "electronicTradeDetails",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Container for Electronic Trade Details"
            ],
            "copyRoute": "customsDetails.invoiceDetails.electronicTradeDetails",
            "subRows": [
              {
                "title": "requestedDocuments",
                "required": "NO",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "object"
                  }
                ],
                "descriptions": [
                  "Container for Requested Documents"
                ],
                "copyRoute": "customsDetails.electronicTradeDetails.requestedDocuments",
                "subRows": [
                  {
                    "title": "documentContent",
                    "required": "CONDITIONAL",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "base64"
                      }
                    ],
                    "descriptions": [
                      "Base 64 format of the contents of the uploaded document."
                    ],
                    "copyRoute": "customsDetails.electronicTradeDetails.requestedDocuments.documentContent"
                  },
                  {
                    "title": "documentDate",
                    "required": "CONDITIONAL",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      },
                      {
                        "title": "Format",
                        "content": "YYYY-MM-DD"
                      }
                    ],
                    "descriptions": [
                      "The date on the document you are uploading.",
                      "Required for all non docs international shipments."
                    ],
                    "copyRoute": "customsDetails.electronicTradeDetails.requestedDocuments.documentDate"
                  },
                  {
                    "title": "invoiceReference",
                    "required": "CONDITIONAL",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      }
                    ],
                    "descriptions": [
                      "The invoice reference.",
                      "Required for all non docs international shipments."
                    ],
                    "copyRoute": "customsDetails.electronicTradeDetails.requestedDocuments.invoiceReference"
                  },
                  {
                    "title": "contentType",
                    "required": "CONDITIONAL",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      },
                      {
                        "title": "Allowed Values",
                        "content": "png, pdf, jpeg, tiff"
                      },
                      {
                        "title": "Limitation",
                        "content": "< 2mb"
                      }
                    ],
                    "descriptions": [
                      "The type of the file you are uploading."
                    ],
                    "copyRoute": "customsDetails.electronicTradeDetails.requestedDocuments.contentType"
                  },
                  {
                    "title": "type",
                    "required": "CONDITIONAL",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      },
                      {
                        "title": "Allowed Values",
                        "content": "COMMERCIAL_INVOICE, PRO_FORMA_INVOICE, CERTIFICATE_OF_ORIGIN, CUSTOMS_DECLARATION"
                      }
                    ],
                    "descriptions": [
                      "Required for generation of electronic invoices."
                    ],
                    "copyRoute": "customsDetails.electronicTradeDetails.requestedDocuments.type"
                  }
                ]
              }
            ]
          },
          {
            "title": "commodities",
            "required": "CONDITIONAL",
            "criteria": [
              {
                "title": "Type",
                "content": "array"
              }
            ],
            "descriptions": [
              ""
            ],
            "copyRoute": "customsDetails.commodities",
            "subRows": [
              {
                "title": "commodityCode",
                "required": "CONDITIONAL",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "3"
                  },
                  {
                    "title": "Max Length",
                    "content": "18"
                  }
                ],
                "descriptions": [
                  "Harmonized HS code for the  commodity."
                ],
                "copyRoute": "customsDetails.commodities.commodityCode"
              },
              {
                "title": "description",
                "required": "CONDITIONAL",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "3"
                  },
                  {
                    "title": "Max Length",
                    "content": "40"
                  }
                ],
                "descriptions": [
                  "Description of the commodity."
                ],
                "copyRoute": "customsDetails.commodities.description"
              },
              {
                "title": "countryOfOrigin",
                "required": "CONDITIONAL",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "ISO 3166 alpha-2 Two character country code."
                  }
                ],
                "descriptions": [
                  "Country where the  commodity is made."
                ],
                "copyRoute": "customsDetails.commodities.countryOfOrigin"
              },
              {
                "title": "quantity",
                "required": "CONDITIONAL",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "object"
                  }
                ],
                "descriptions": [
                  "Container for Commodity Quantity"
                ],
                "copyRoute": "customsDetails.commodities.quantity",
                "subRows": [
                  {
                    "title": "value",
                    "required": "YES",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "integer"
                      }
                    ],
                    "descriptions": [
                      "The number of pieces of the commodity."
                    ],
                    "copyRoute": "customsDetails.commodities.quantity.value"
                  },
                  {
                    "title": "unit",
                    "required": "YES",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      },
                      {
                        "title": "Allowed Values",
                        "content": "PCS"
                      }
                    ],
                    "descriptions": [
                      "Must be PCS"
                    ],
                    "copyRoute": "customsDetails.commodities.quantity.unit"
                  }
                ]
              },
              {
                "title": "unitPrice",
                "required": "CONDITIONAL",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "object"
                  }
                ],
                "descriptions": [
                  "Container for Commodity Unit Price"
                ],
                "copyRoute": "customsDetails.commodities.unitPrice",
                "subRows": [
                  {
                    "title": "value",
                    "required": "YES",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "double"
                      },
                      {
                        "title": "Allowed Values",
                        "content": "Up to 2dp"
                      }
                    ],
                    "descriptions": [
                      "Value of the commodity."
                    ],
                    "copyRoute": "customsDetails.commodities.unitPrice.value"
                  }
                ]
              },
              {
                "title": "weight",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "object"
                  }
                ],
                "descriptions": [
                  "Container for Commodity Weight"
                ],
                "copyRoute": "customsDetails.commodities.weight",
                "subRows": [
                  {
                    "title": "value",
                    "required": "YES",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "double"
                      },
                      {
                        "title": "Allowed Values",
                        "content": "Up to 2dp"
                      }
                    ],
                    "descriptions": [
                      "Weight of the shipment",
                      "Total of all commodities weights must match the weight entered on quote request"
                    ],
                    "copyRoute": "customsDetails.commodities.weight.value"
                  },
                  {
                    "title": "unit",
                    "required": "YES",
                    "criteria": [
                      {
                        "title": "Type",
                        "content": "string"
                      },
                      {
                        "title": "Allowed Values",
                        "content": "KG or LB"
                      }
                    ],
                    "descriptions": [
                      "Code representing the unit of measure associated with the package weight.",
                      "Must match the weight unit entered on the quote request."
                    ],
                    "copyRoute": "customsDetails.commodities.weight.unit"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "title": "parcels",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "array"
          }
        ],
        "descriptions": [
          "Parcel Details Container"
        ],
        "subRows": [
          {
            "title": "dimensions",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Container for parcel dimensions"
            ],
            "copyRoute": "parcels.dimensions",
            "subRows": [
              {
                "title": "length",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "double"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "Up to 2dp"
                  }
                ],
                "descriptions": [
                  "Length of the package."
                ],
                "copyRoute": "parcels.dimensions.length"
              },
              {
                "title": "width",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "double"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "Up to 2dp"
                  }
                ],
                "descriptions": [
                  "Width of the package."
                ],
                "copyRoute": "parcels.dimensions.width"
              },
              {
                "title": "height",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "double"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "Up to 2dp"
                  }
                ],
                "descriptions": [
                  "Height of the package."
                ],
                "copyRoute": "parcels.dimensions.height"
              },
              {
                "title": "unit",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "IN or CM"
                  }
                ],
                "descriptions": [
                  "Package dimensions unit of measurement code.",
                  "IN = Inches, CM = Centimeters."
                ],
                "copyRoute": "parcels.dimensions.unit"
              }
            ]
          },
          {
            "title": "weight",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Package Weight Container."
            ],
            "copyRoute": "parcels.weight",
            "subRows": [
              {
                "title": "value",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "double"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "Up to 2dp"
                  }
                ],
                "descriptions": [
                  "Weight of the package."
                ],
                "copyRoute": "parcels.weight.value"
              },
              {
                "title": "unit",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "KG or LB"
                  }
                ],
                "descriptions": [
                  "Code representing the unit of measure associated with the package weight."
                ],
                "copyRoute": "parcels.weight.unit"
              }
            ]
          }
        ]
      },
      {
        "title": "customerShippingReference",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          },
          {
            "title": "Min Length",
            "content": "3"
          },
          {
            "title": "Max Length",
            "content": "29"
          }
        ],
        "descriptions": [
          "Unique customer reference."
        ]
      },
      {
        "title": "contentDescription",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          },
          {
            "title": "Min Length",
            "content": "3"
          },
          {
            "title": "Max Length",
            "content": "40"
          }
        ],
        "descriptions": [
          "Description of shipment contents."
        ]
      },
      {
        "title": "deliveryInstructions",
        "required": "NO",
        "criteria": [
          {
            "title": "Type",
            "content": "string"
          },
          {
            "title": "Min Length",
            "content": "3"
          },
          {
            "title": "Max Length",
            "content": "29"
          }
        ],
        "descriptions": [
          "For passing in any delivery instructions for the carrier."
        ]
      },
      {
        "title": "specialService",
        "required": "NO",
        "criteria": [
          {
            "title": "Type",
            "content": "array<string>"
          },
          {
            "title": "Allowed Values",
            "content": "INSURANCE, ELECTRONIC_TRADE_DOCUMENTS, DELIVERED_DUTY_PAID"
          }
        ],
        "descriptions": [
          "If insurance required please add INSURANCE as value in special services.",
          "If you are using electronic trade ( uploading a commercial invoice ) please provide ELECTRONIC_TRADE_DOCUMENTS  as a special service."
        ]
      },
      {
        "title": "shipmentType",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "enum"
          },
          {
            "title": "Allowed Values",
            "content": "DOCS or NON_DOCS"
          }
        ],
        "descriptions": [
          "The value for the shipment type will be auto populated from the quote request."
        ]
      },
      {
        "title": "label",
        "required": "YES",
        "criteria": [
          {
            "title": "Type",
            "content": "object"
          }
        ],
        "descriptions": [
          "Container for label information."
        ],
        "subRows": [
          {
            "title": "type",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "enum"
              },
              {
                "title": "Allowed Values",
                "content": "THERMAL or PDF"
              }
            ],
            "descriptions": [
              "The label type of your shipment label."
            ],
            "copyRoute": "label.type"
          },
          {
            "title": "size",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "enum"
              },
              {
                "title": "Allowed Values",
                "content": "A4, US_LETTER, STOCK_4x6"
              }
            ],
            "descriptions": [
              "The label size of your shipment label.",
              "If PDF is chosen then options are A4 and US_LETTER.",
              "If THERMAL is chosen then STOCK_4x6 must be used."
            ],
            "copyRoute": "label.size"
          }
        ]
      },
      {
        "title": "brokerDetails",
        "required": "CONDITIONAL",
        "criteria": [
          {
            "title": "Type",
            "content": "object"
          }
        ],
        "descriptions": [
          "This object is filled out if you have a customs broker that will clear the shipment for the consignee."
        ],
        "subRows": [
          {
            "title": "address",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Container for Broker Address."
            ],
            "copyRoute": "brokerDetails.address",
            "subRows": [
              {
                "title": "organisation",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "3"
                  },
                  {
                    "title": "Max Length",
                    "content": "35"
                  }
                ],
                "descriptions": [
                  "Broker's organisation."
                ],
                "copyRoute": "brokerDetails.address.organisation"
              },
              {
                "title": "streetLines",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "array<string>"
                  },
                  {
                    "title": "Max Length",
                    "content": "35 per line"
                  }
                ],
                "descriptions": [
                  "Broker's street lines"
                ],
                "copyRoute": "brokerDetails.address.streetLines"
              },
              {
                "title": "stateOrCounty",
                "required": "CONDITIONAL",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  }
                ],
                "descriptions": [
                  "Broker's state or county"
                ],
                "copyRoute": "brokerDetails.address.stateOrCounty"
              },
              {
                "title": "countryCode",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "ISO 3166-1 alpha-2"
                  }
                ],
                "descriptions": [
                  "Broker's country code."
                ],
                "link": {
                  "title": "Country Codes",
                  "url": "/api/reference-data#country-codes"
                },
                "copyRoute": "brokerDetails.address.countryCode"
              },
              {
                "title": "postalCode",
                "required": "CONDITIONAL",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  }
                ],
                "descriptions": [
                  "Broker's postal code."
                ],
                "copyRoute": "brokerDetails.address.postalCode"
              },
              {
                "title": "city",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  }
                ],
                "descriptions": [
                  "Broker's city."
                ],
                "copyRoute": "brokerDetails.address.city"
              }
            ]
          },
          {
            "title": "contactDetails",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Container for broker's contact details."
            ],
            "copyRoute": "brokerDetails.contactDetails",
            "subRows": [
              {
                "title": "name",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Min Length",
                    "content": "3"
                  },
                  {
                    "title": "Max Length",
                    "content": "35"
                  }
                ],
                "descriptions": [
                  "Broker's name."
                ],
                "copyRoute": "brokerDetails.contactDetails.name"
              },
              {
                "title": "telephone",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  }
                ],
                "descriptions": [
                  "Broker's telephone number."
                ],
                "copyRoute": "brokerDetails.contactDetails.telephone"
              }
            ]
          },
          {
            "title": "notificationDetails",
            "required": "YES",
            "criteria": [
              {
                "title": "Type",
                "content": "object"
              }
            ],
            "descriptions": [
              "Container for broker's notification details."
            ],
            "copyRoute": "brokerDetails.notificationDetails",
            "subRows": [
              {
                "title": "email",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  },
                  {
                    "title": "Allowed Values",
                    "content": "<>@<>.<>"
                  }
                ],
                "descriptions": [
                  "Broker's email address."
                ],
                "copyRoute": "brokerDetails.notificationDetails.email"
              },
              {
                "title": "mobile",
                "required": "YES",
                "criteria": [
                  {
                    "title": "Type",
                    "content": "string"
                  }
                ],
                "descriptions": [
                  "Broker's mobile telephone number."
                ],
                "copyRoute": "brokerDetails.notificationDetails.mobile"
              }
            ]
          }
        ]
      }
    ],
    "responseSuccess": [
      {
        "title": "200 Success",
        "criteria": [
          {
            "title": "Type",
            "content": "array"
          }
        ],
        "descriptions": [
          ""
        ],
        "subRows": [
          {
            "title": "quoteId",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "Indicates the unique identifier for a specific rate quotation."
            ]
          },
          {
            "title": "carrierServiceName",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "Name of the service saved by AMI."
            ]
          },
          {
            "title": "carrierProductCode",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              }
            ],
            "descriptions": [
              "Name of the service as returned by the carrier."
            ]
          },
          {
            "title": "serviceType",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              },
              {
                "title": "Enum",
                "content": "DROP_IN | COLLECTED"
              }
            ],
            "descriptions": [
              "Represents the Service offered for the Carrier."
            ]
          },
          {
            "title": "transportMethod",
            "criteria": [
              {
                "title": "Type",
                "content": "string"
              },
              {
                "title": "Enum",
                "content": "ROAD | EXPRESS"
              }
            ],
            "descriptions": [
              "Represents the Mode of transport for the shipment."
            ]
          }
        ]
      }
    ],
    "responseError": [
      {
        "title": "1000",
        "descriptions": [
          "must not be null"
        ]
      },
      {
        "title": "1010",
        "descriptions": [
          "must not be null for country"
        ]
      },
      {
        "title": "1100",
        "descriptions": [
          "must be true/false"
        ]
      },
      {
        "title": "1200",
        "descriptions": [
          "must be a number"
        ]
      },
      {
        "title": "1210",
        "descriptions": [
          "must be up to % decimal places only"
        ]
      },
      {
        "title": "1220",
        "descriptions": [
          "must be between % and %"
        ]
      },
      {
        "title": "1230",
        "descriptions": [
          "must be equal to or greater than %"
        ]
      },
      {
        "title": "1240",
        "descriptions": [
          "must be equal to or less than %"
        ]
      },
      {
        "title": "1300",
        "descriptions": [
          "must be one of"
        ]
      },
      {
        "title": "1310",
        "descriptions": [
          "must be one of for country %"
        ]
      },
      {
        "title": "1320",
        "descriptions": [
          "must be one of when % is %"
        ]
      },
      {
        "title": "1400",
        "descriptions": [
          "invalid length"
        ]
      },
      {
        "title": "1500",
        "descriptions": [
          "invalid format"
        ]
      },
      {
        "title": "1510",
        "descriptions": [
          "invalid format for country %"
        ]
      },
      {
        "title": "1550",
        "descriptions": [
          "invalid date"
        ]
      },
      {
        "title": "1600",
        "descriptions": [
          "not empty"
        ]
      },
      {
        "title": "1610",
        "descriptions": [
          "invalid size"
        ]
      }
    ]
  }
}`