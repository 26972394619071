import axios from "axios";
import {CustomerDetails} from "../features/customerSlice";
import {Booking, bookingActions} from "../features/bookingSlice";
import {KG_TO_LB_MULTIPLICATION} from "../pages/single-booking/confirm-page/utils";
import {getCurrencyConversionRate, getCurrencyData} from "./APIUtilities";

export interface FactParamItem {
  "name": string,
  "value": any
}

export interface FactParamItems extends Array<FactParamItem> {}

export interface Fact {
  "fieldName": string,
  "originTradeBloc": string,
  "params": FactParamItems
}

export interface Rule {
  fieldName: string,
  type: string,
  value: boolean,
  passValue: any,
  failValue: any
}

export interface RuleItems extends Array<Rule> {}

export enum TradeRoute {
  DOMESTIC = 'domestic',
  EXPORT = 'export',
  IMPORT = 'import',
  CROSSTRADE = 'cross-trade'
}

export enum B13AFilingOptionEnum {
  FILED_ELECTRONICALLY = "FILED_ELECTRONICALLY",
  MANUALLY_ATTACHED = "MANUALLY_ATTACHED",
  NOT_REQUIRED = "NOT_REQUIRED",
  SUMMARY_REPORTING = "SUMMARY_REPORTING"
}

export function getTradeRoute(customer: CustomerDetails, booking: Booking) {
  let route;

  switch (true) {
    case booking.origin.value === booking.destination.value:
      route = TradeRoute.DOMESTIC;
      break;
    case booking.origin.value !== booking.destination.value && booking.origin.value === customer.customerCountryCode:
      route = TradeRoute.EXPORT
      break;
    case booking.origin.value !== booking.destination.value && booking.destination.value === customer.customerCountryCode:
      route = TradeRoute.IMPORT
      break;
    case booking.origin.value !== booking.destination.value && (
      booking.origin.value !== customer.customerCountryCode && booking.destination.value !== customer.customerCountryCode
    ):
      route = TradeRoute.CROSSTRADE
  }
  return route
}

export function isIntraEu(booking: Booking) {
  return booking.destination.tradeBloc === "EUCU" && booking.origin.tradeBloc === "EUCU"
}

export async function callRulesEngine(facts: any, config: any) {
  let results;
  await axios.post(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/rules/retrieve-rules`,
    {"facts": facts},
    config
  ).then((response: any) => {
    results = response.data;
  }, (error) => {
    console.error('Rules engine error', error)
  })
  return results;
}

const retrieveAndProcessRules = async (facts: any, config: any, rules: any) => {
  await callRulesEngine(facts, config)
    .then(
      (results: any) => {
        results.passed.forEach((result: any) => {
          rules.forEach((rule: any) => {
            if (result.type === rule.fieldName) {
              rule.value = true
            }
          });
        });
        results.failed.forEach((result: any) => {
          rules.forEach((rule: any) => {
            if (result.type === rule.fieldName) {
              rule.value = false
            }
          });
        });
      }
    )
    .catch((error: any) => console.error(error))
}

function resetRules(rules: any) {
  rules.forEach((rule: any) => {
    rule.value = rule.fieldName === "commodity";
  });
}

const returnShipmentWeightMetric = async (booking: Booking) => {
  if (booking.imperialMetric === 'METRIC') {
    return booking.selectedQuote.weight.actual.value
  } else {
    return booking.selectedQuote.weight.actual.value / KG_TO_LB_MULTIPLICATION
  }
}

const getCarrierNameAsync = async (quoteId: string) => {
  return quoteId.split('-')[0];
}

const getETradeByCarrier = async (booking: Booking) => {
  let carrier = "";
  await getCarrierNameAsync(booking.selectedQuote.quoteId)
    .then((result: any) => {
      carrier = result
    });
  switch (carrier) {
    case "dhl":
      return booking.destination.eTrade_dhl_import
    case "fedex":
      return booking.destination.eTrade_fdx_import
    case "ups":
      return booking.destination.eTrade_ups_import
    default:
      return null;
  }
}

const getETrade = async (booking: Booking, customer: CustomerDetails) => {
  const etradeByCarrier = await getETradeByCarrier(booking);
  // As we are storing ints and boolean values in the same field, we need to parse the string values of each
  // whether they be "true", "false" or an integer (the integer being the value limit for electronic trade)
  if (etradeByCarrier === "true") {
    return true
  } else if (etradeByCarrier === "false") {
    return false
  } else {
    return parseInt(etradeByCarrier) >= await convertShipmentValue(booking, customer, 'USD')
  }
}

const getShipmentOrigin = async (booking: Booking) => {
  if (booking.origin.tradeBloc !== "") return booking.origin.tradeBloc
  else return booking.origin.value
}

export async function convertShipmentValue(booking: Booking, customerDetails: CustomerDetails, preferredCurrency: string) {
  if (booking.preferredCurrency.value !== preferredCurrency) {
    const apiConfig = {
      headers: {
        "Content-Type": "application/json",
        "authorization": `Bearer ${customerDetails.auth.accessToken}`
      }
    };
    if (preferredCurrency === "USD") {
      let currency: any;
      await getCurrencyData(apiConfig, booking.preferredCurrency.value)
        .then((response) => {
          currency = response;
        });
      return +booking.totalShipmentValue / currency.dollarExchangeRate;
    } else {
      const conversionRate = await getCurrencyConversionRate(
        apiConfig,
        booking.preferredCurrency.value,
        preferredCurrency
      );
      return +booking.totalShipmentValue * conversionRate;
    }
  } else {
    return +booking.totalShipmentValue;
  }
}

function isNorthernIreland(postalCode: string) {
  return postalCode.slice(0, 2) === "BT";
}

let bookingRules: RuleItems = [
  {
    fieldName: "aesItn",
    type: "validation",
    value: false,
    passValue: null,
    failValue: null
  },
  {
    fieldName: "exportDeclarationNumber",
    type: "validation",
    value: false,
    passValue: true,
    failValue: false
  },
  {
    fieldName: "customerCustomsNumber",
    type: "validation",
    value: false,
    passValue: true,
    failValue: false
  },
  {
    fieldName: "aesItnVisible",
    type: "display",
    value: false,
    passValue: null,
    failValue: null
  },
  {
    fieldName: "shipperEori",
    type: "display",
    value: false,
    passValue: null,
    failValue: null
  },
  {
    fieldName: "noEEIExemption",
    type: "validation",
    value: false,
    passValue: "No EEI 30.36",
    failValue: null
  },
  {
    fieldName: "commodityExemption",
    type: "display",
    value: false,
    passValue: null,
    failValue: null
  },
  {
    fieldName: "polandDocsException",
    type: "validation",
    value: false,
    passValue: null,
    failValue: null
  },
  {
    fieldName: "collCountyStateRequired",
    type: "validation",
    value: false,
    passValue: null,
    failValue: null
  },
  {
    fieldName: "docsShipmentValueRequired",
    type: "validation",
    value: false,
    passValue: null,
    failValue: null
  },
  {
    fieldName: "eTrade",
    type: "display",
    value: true,
    passValue: {
      dhl: {
        generate: true,
        upload: true
      },
      fedex: {
        generate: false,
        upload: true
      }
    },
    failValue: false
  },
  {
    fieldName: "UKPreferentialOriginStatement",
    type: "display",
    value: false,
    passValue: true,
    failValue: false
  },
  {
    fieldName: "upsCollCountyStateHidden",
    type: "display",
    value: false,
    passValue: null,
    failValue: null
  },
  {
    fieldName: "upsCneeCountyStateHidden",
    type: "display",
    value: false,
    passValue: null,
    failValue: null
  },
  {
    fieldName: "southAfricaExportersCode",
    type: "display",
    value: false,
    passValue: null,
    failValue: null
  },
  {
    fieldName: "canaryIslandsBroker",
    type: "display",
    value: false,
    passValue: null,
    failValue: null
  },
  {
    fieldName: "b13ALabelText",
    type: "any",
    value: false,
    passValue: "- if not entered must be attached to shipment",
    failValue: ""
  }
];
export async function handleBookingRules(customer: CustomerDetails, booking: Booking) {

  await resetRules(bookingRules);

  const shipmentOrigin: string = await getShipmentOrigin(booking);
  const usdShipmentValue: number = await convertShipmentValue(booking, customer, 'USD');
  const eurShipmentValue:number = await convertShipmentValue(booking, customer, 'EUR');
  const audShipmentValue: number = await convertShipmentValue(booking, customer, 'AUD');
  const nzdShipmentValue: number = await convertShipmentValue(booking, customer, 'NZD');
  const tradeRoute: TradeRoute | undefined = await getTradeRoute(customer, booking);
  const carrierName: string = await getCarrierNameAsync(booking.selectedQuote.quoteId);
  const weightMetric: number = await returnShipmentWeightMetric(booking);
  const eTrade: boolean = await getETrade(booking, customer);
  const countryOfResidence: string = customer.countryOfResidence.value;
  const isNorthernIrelandDestination: boolean = isNorthernIreland(booking.destinationPostalCode);
  const isNorthernIrelandOrigin: boolean = isNorthernIreland(booking.originPostalCode);
  const cadShipmentValue = await convertShipmentValue(booking, customer, 'CAD')

  const config = {
    headers: {
      "Content-Type": "application/json",
      "authorization": `Bearer ${customer.auth.accessToken}`
    }
  };

  const facts = [
    <Fact>{
      fieldName: 'aesItn',
      originTradeBloc: booking.origin.value,
      params: [
        {
          name: 'usdShipmentValue',
          value: usdShipmentValue
        },
        {
          name: 'tradeRoute',
          value: tradeRoute
        }
      ]
    },
    <Fact>{
      fieldName: 'exportDeclarationNumber',
      originTradeBloc: booking.origin.value,
      params: [
        {
          name: 'audShipmentValue',
          value: audShipmentValue
        },
        {
          name: 'tradeRoute',
          value: tradeRoute
        },
        {
          name: 'carrier',
          value: carrierName
        }
      ]
    },
    <Fact>{
      fieldName: 'customerCustomsNumber',
      originTradeBloc: booking.origin.value,
      params: [
        {
          name: 'nzdShipmentValue',
          value: nzdShipmentValue
        },
        {
          name: 'tradeRoute',
          value: tradeRoute
        },
        {
          name: 'countryOfResidence',
          value: countryOfResidence
        }
      ]
    },
    <Fact>{
      fieldName: 'aesItnVisible',
      originTradeBloc: 'ALL',
      params: [
        {
          name: 'destinationCountry',
          value: booking.destination.value
        },
        {
          name: 'originCountry',
          value: booking.origin.value
        }
      ]
    },
    <Fact>{
      fieldName: 'shipperEori',
      originTradeBloc: shipmentOrigin,
      params: [
        {
          name: 'eurShipmentValue',
          value: eurShipmentValue
        },
        {
          name: 'destinationTradeBloc',
          value: booking.destination.tradeBloc
        }
      ]
    },
    <Fact>{
      fieldName: 'noEEIExemption',
      originTradeBloc: shipmentOrigin,
      params: [
        {
          name: 'destinationCountry',
          value: booking.destination.value
        },
        {
          name: 'usdShipmentValue',
          value: usdShipmentValue
        }
      ]
    },
    <Fact>{
      fieldName: 'commodityExemption',
      originTradeBloc: 'ALL',
      params: [
        {
          name: 'shipmentType',
          value: booking.shipmentType
        },
        {
          name: 'tradeRoute',
          value: tradeRoute
        },
        {
          name: 'shipmentWeight',
          value: booking.selectedQuote.weight.actual.value
        },
        {
          name: 'carrier',
          value: carrierName
        },
        {
          name: 'destinationTradeBloc',
          value: booking.destination.tradeBloc
        },
        {
          name: 'originTradeBloc',
          value: booking.origin.tradeBloc
        },
        {
          name: 'destinationCountry',
          value: booking.destination.value
        },
        {
          name: 'originCountry',
          value: booking.origin.value
        },
        {
          name: 'destinationNorthernIreland',
          value: isNorthernIrelandDestination
        },
        {
          name: 'originNorthernIreland',
          value: isNorthernIrelandOrigin
        }
      ]
    },
    <Fact>{
      fieldName: 'collCountyStateRequired',
      originTradeBloc: 'ALL',
      params: [
        {
          name: 'originCountry',
          value: booking.origin.value
        },
        {
          name: 'serviceType',
          value: booking.selectedQuote.serviceType
        },
        {
          name: 'carrier',
          value: carrierName
        },
        {
          name: 'originTradeBloc',
          value: booking.origin.tradeBloc
        }
      ]
    },
    <Fact>{
      fieldName: 'polandDocsException',
      originTradeBloc: 'ALL',
      params: [
        {
          name: 'destinationCountry',
          value: booking.destination.value
        },
        {
          name: 'shipmentWeight',
          value: weightMetric
        }
      ]
    },
    <Fact>{
      fieldName: 'docsShipmentValueRequired',
      originTradeBloc: 'ALL',
      params: [
        {
          name: 'shipmentType',
          value: booking.shipmentType
        },
        {
          name: 'shipmentWeight',
          value: weightMetric
        }
      ]
    },
    <Fact>{
      fieldName: 'eTrade',
      originTradeBloc: 'ALL',
      params: [
        {
          name: 'carrier',
          value: carrierName
        },
        {
          name: 'eTradeCompatible',
          value: eTrade
        }
      ]
    },
    <Fact>{
      fieldName: 'UKPreferentialOriginStatement',
      originTradeBloc: shipmentOrigin,
      params: [
        {
          name: 'destinationTradeBloc',
          value: booking.destination.tradeBloc
        },
        {
          name: 'carrier',
          value: carrierName
        }
      ]
    },
    <Fact>{
      fieldName: 'upsCollCountyStateHidden',
      originTradeBloc: 'ALL',
      params: [
        {
          name: 'carrier',
          value: carrierName
        },
        {
          name: 'origin',
          value: booking.origin.value
        }
      ]
    },
    <Fact>{
      fieldName: 'upsCneeCountyStateHidden',
      originTradeBloc: 'ALL',
      params: [
        {
          name: 'carrier',
          value: carrierName
        },
        {
          name: 'destinationCountry',
          value: booking.destination.value
        }
      ]
    },
    <Fact>{
      fieldName: 'southAfricaExportersCode',
      originTradeBloc: booking.origin.value,
      params: [
        {
          name: 'shipmentType',
          value: booking.shipmentType
        },
        {
          name: 'tradeRoute',
          value: tradeRoute
        }
      ]
    },
    <Fact>{
      fieldName: 'canaryIslandsBroker',
      originTradeBloc: booking.origin.value,
      params: [
        {
          name: 'carrier',
          value: carrierName
        },
        {
          name: 'destinationCountry',
          value: booking.destination.value
        },
        {
          name: 'shipmentType',
          value: booking.shipmentType
        }
      ]
    },
    <Fact>{
      fieldName: 'b13ALabelText',
      originTradeBloc: booking.origin.value,
      params: [
        {
          name: 'destinationCountry',
          value: booking.destination.value
        },
        {
          name: 'cadShipmentValue',
          value: cadShipmentValue
        }
      ]
    }
  ];

  await retrieveAndProcessRules(facts, config, bookingRules);

  // AES/ITN Visibility rule will always override AES/ITN rule (can't be mandatory and invisible)
  if (extractRule(bookingRules, 'aesItnVisible').value === false) {
    extractRule(bookingRules, 'aesItn').value = false
  }

  return bookingRules;
}

// CANADA b13 a rules processed on confirm page load
let b13aRules: RuleItems = [
  {
    fieldName: "b13a_NonUsHighValuePOR",
    type: "validation",
    value: false,
    passValue: B13AFilingOptionEnum.FILED_ELECTRONICALLY,
    failValue: null
  },
  {
    fieldName: "b13a_NonUsHighValueNoPOR",
    type: "validation",
    value: false,
    passValue: B13AFilingOptionEnum.MANUALLY_ATTACHED,
    failValue: null
  },
  {
    fieldName: "b13a_NonUsLowValuePOR",
    type: "validation",
    value: false,
    passValue: B13AFilingOptionEnum.SUMMARY_REPORTING,
    failValue: null
  }
]

export async function handleB13aRules(customer: CustomerDetails, booking: Booking, dispatch:any) {

  await resetRules(b13aRules);

  const cadShipmentValue = await convertShipmentValue(booking, customer, 'CAD')

  const config = {
    headers: {
      "Content-Type": "application/json",
      "authorization": `Bearer ${customer.auth.accessToken}`
    }
  };

  const facts = [
    <Fact>{
      fieldName: 'b13a_NonUsHighValuePOR',
      originTradeBloc: booking.origin.value,
      params: [
        {
          name: 'destinationCountry',
          value: booking.destination.value
        },
        {
          name: 'cadShipmentValue',
          value: cadShipmentValue
        },
        {
          name: 'hasProofOfRecord',
          value: !!booking.shipperDetails.exportComplianceStatement.aes
        }
      ]
    },
    <Fact>{
      fieldName: 'b13a_NonUsHighValueNoPOR',
      originTradeBloc: booking.origin.value,
      params: [
        {
          name: 'destinationCountry',
          value: booking.destination.value
        },
        {
          name: 'cadShipmentValue',
          value: cadShipmentValue
        },
        {
          name: 'hasProofOfRecord',
          value: !!booking.shipperDetails.exportComplianceStatement.aes
        }
      ]
    },
    <Fact>{
      fieldName: 'b13a_NonUsLowValuePOR',
      originTradeBloc: booking.origin.value,
      params: [
        {
          name: 'destinationCountry',
          value: booking.destination.value
        },
        {
          name: 'cadShipmentValue',
          value: cadShipmentValue
        },
        {
          name: 'hasProofOfRecord',
          value: !!booking.shipperDetails.exportComplianceStatement.aes
        }
      ]
    }
  ];

  await retrieveAndProcessRules(facts, config, b13aRules);

  // Only one b13A rule should ever pass, therefore find the one that does and return it
  b13aRules.forEach((rule: Rule) => {
    if (rule.value) {
      dispatch(bookingActions.setB13AFilingOption(rule.passValue));
    }
  });
}

export async function handleHomePageRules(customerDetails: CustomerDetails) {

  let homePageRules: RuleItems = [
    {
      fieldName: "bulkBookingVisible",
      type: "validation",
      value: false,
      passValue: null,
      failValue: null
    }
  ]

  await resetRules(homePageRules);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "authorization": `Bearer ${customerDetails.auth.accessToken}`
    }
  };

  const facts = [
    <Fact>{
      fieldName: 'bulkBookingVisible',
      originTradeBloc: "ALL",
      params: [
        {
          name: 'partyId',
          value: customerDetails.creditCheck.tmffPartyId
        }
      ]
    }
  ]

  await retrieveAndProcessRules(facts, config, homePageRules);

  return homePageRules;
}

export const extractRule = (rules: any, field: string) => {
  if (Object.keys(rules).length > 0) {
    return rules.filter((rule: any) => rule.fieldName === field)[0];
  }
}