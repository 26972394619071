import React, {ChangeEvent, useEffect, useState} from 'react';
import './ShipmentTypeCard.scss';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { AMIAlert, AMIFormElement, AMIInput, AMIMultiButton, AMISelect, Icon } from '../../../../ui-components/UiComponents';
import { appDataActions, appDataSelector } from '../../../../features/appDataSlice';
import { bookingActions, bookingSelector, ImperialMetric, Piece, ShipmentType } from '../../../../features/bookingSlice';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import { formatShipmentTypeForDisplay } from '../../../../utilities/HelperUtilities';
import { checkIfTotalValueIsRequired, getTotalWeight } from '../utils';
import ShipmentPiece from './shipment-piece/ShipmentPiece';
import { getCurrencyData } from "../../../../utilities/APIUtilities";
import getSymbolFromCurrency from 'currency-symbol-map';

const ShipmentTypeCard: React.FC<{errHandler: any}> = ({errHandler}) => {

  const dispatch = useAppDispatch();
  const booking = useAppSelector(bookingSelector);
  const appData = useAppSelector(appDataSelector);
  const customer = useAppSelector(customerDetailsSelector);
  const showErrors = appData.showHomePageErrors;
  const shipmentPieces = booking.pieces;
  const shipmentType = booking.shipmentType;
  const apiConfig = appData.apiConfig;
  const [currencies, setCurrencies] = useState<any>([]);

  const handleAddPiece = () => {
    dispatch(bookingActions.addPiece(
      {
        weight: '',
        length: '',
        width: '',
        height: '',
        value: ''
      } as Piece,
    ));
  }

  const copyPiece = (piece: any, index: number) => {
    const pieceCopy = {...piece};
    const piecesCopy = [...shipmentPieces];
    piecesCopy.splice(index + 1, 0, pieceCopy);
    dispatch(bookingActions.setPieces(piecesCopy));
  }

  const deletePiece = (index: number) => {
    if (shipmentPieces.length > 1) {
      const piecesCopy = [...shipmentPieces];
      piecesCopy.splice(index, 1);
      dispatch(bookingActions.setPieces(piecesCopy));
    }
  }

  const onTotalShipmentValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(bookingActions.updateField({
      field: 'totalShipmentValue',
      value: event.target.value
    }))
  }

  useEffect(() => {
    getCurrencyData(apiConfig, null)
      .then((data: any) => {
        data.sort((a: any, b:any) => a.code.localeCompare(b.code));
        setCurrencies(data.map((currency: any) => {
          const symbol = getSymbolFromCurrency(currency.code) ? getSymbolFromCurrency(currency.code) : '';
          return {
            title: symbol + ' ' + currency.code,
            value: currency.code,
            symbol
          }
        }))
      });
  }, [])

  const dispatchPreferredCurrency = (currency: any) => {
    dispatch(bookingActions.updateField(
      {
        field: "preferredCurrency",
        value: currency
      }
    ))
  }

  const setPreferredCurrency = () => {
    if (
      !appData.preferredCurrencyChanged
      && booking.preferredCurrency?.value !== customer.countryOfResidence.currencyCode
    ) {
      let currencyCode = customer.countryOfResidence?.currencyCode;
      let currency = currencies.filter((currency: any) => {
        return currency.value === currencyCode;
      })[0];
      dispatchPreferredCurrency(currency);
    }
  }

  const onCurrencyChange = (currency: any) => {
    dispatchPreferredCurrency(currency);
    dispatch(appDataActions.updateField({field: 'preferredCurrencyChanged', value: true}));
  }

  useEffect(() => {
    setPreferredCurrency();
  }, [currencies, customer.countryOfResidence])

  return (
    <div className="shipment-type horizontal-card">
      <p className="shipment-type__header">Shipment Type:</p>

      <AMIMultiButton
        buttons={customer.customerCountryCode === "GB" ? (
          [
            {title: formatShipmentTypeForDisplay(ShipmentType.NON_DOCS), value: ShipmentType.NON_DOCS},
            {title: formatShipmentTypeForDisplay(ShipmentType.DOCS), value: ShipmentType.DOCS},
          ]
        ) : (
          [
            {title: formatShipmentTypeForDisplay(ShipmentType.NON_DOCS), value: ShipmentType.NON_DOCS},
            {title: formatShipmentTypeForDisplay(ShipmentType.DOCS), value: ShipmentType.DOCS},
            {title: formatShipmentTypeForDisplay(ShipmentType.ENVELOPE), value: ShipmentType.ENVELOPE},
          ]
        )}
        selected={{title: formatShipmentTypeForDisplay(shipmentType), value: shipmentType}}
        style={{
          width: '300px',
          marginBottom: '12px'
        }}
        onClick={(event: any) => dispatch(bookingActions.updateField({
          field: 'shipmentType',
          value: event.value
        }))}
      >

      </AMIMultiButton>

      {shipmentType === ShipmentType.ENVELOPE
      && <p className="shipment-type__warning">Please note, the Envelope rate is valid for document shipments only. The rate is valid for documents under 0.5 lbs (about 25 pages). Shipments found to be non-documents will incur additional charges.</p>}

      {shipmentType === ShipmentType.NON_DOCS
      && <>
        <p className="shipment-type__warning">Please note, Dangerous Goods (DG) shipments are not available at this time.</p>
      </>}

      <AMIFormElement
        label="Total Shipment Value"
        errorMessage={
          showErrors
          && !errHandler.totalShipmentValue.criteria
            ? errHandler.totalShipmentValue.message
            : ""
        }
      >
        <AMIInput
          name="total-value"
          type="number"
          size="large"
          min="0"
          placeholder={
            checkIfTotalValueIsRequired(customer, booking)
              ? booking.preferredCurrency?.symbol + " Required"
              : booking.preferredCurrency?.symbol
          }
          value={booking.totalShipmentValue}
          onChange={onTotalShipmentValueChange}
        />
      </AMIFormElement>

      <div className="shipment-type__currency-picker">
        <AMISelect
          name="currency-picker"
          tabIndex={-1}
          options={
            currencies
          }
          defaultValue={booking.preferredCurrency}
          style={{
            border: booking.preferredCurrency?.value ? 'none' : 'red solid 1px',
            width: '90px',
          }}
          isSearchable
          hideClearButton
          onChange={onCurrencyChange}
        ></AMISelect>
      </div>

      <div>
        {shipmentType !== ShipmentType.ENVELOPE && shipmentPieces.map((piece, index) => {
          return (
            <div
              key={index}
            >
              <div className="shipment-type__piece-counter">Piece {index + 1}</div>
              <ShipmentPiece
                piece={piece}
                index={index}
                isLastPiece={
                  shipmentPieces.length === index + 1
                }
                isOnlyPiece={shipmentPieces.length === 1}
                shipmentType={shipmentType}
                onCopy={() => copyPiece(piece, index)}
                onDelete={() => deletePiece(index)}
              ></ShipmentPiece>
            </div>
          )
        })}

        {shipmentType === ShipmentType.ENVELOPE
        && (
          <ShipmentPiece
            piece={shipmentPieces[0]}
            index={0}
            isLastPiece={true}
            isOnlyPiece={shipmentPieces.length === 1}
            shipmentType={shipmentType}
            onCopy={() => null}
            onDelete={() => null}
          ></ShipmentPiece>
        )}
      </div>

      {shipmentPieces.length > 30 && (
        <AMIAlert variant="warning" style={{marginBottom: '24px'}}>FedEx will only allow shipments of up to 30 parcels, please split into multiple booking requests if you wish to get a quote for their services.</AMIAlert>
      )}

      <p className="shipment-type__piece-totals">Pieces: <strong>{shipmentPieces.length}</strong>
        <br /> Total weight: <strong>{getTotalWeight(booking)}{booking.imperialMetric === ImperialMetric.IMPERIAL ? 'lb' : 'kg'}</strong>
      </p>

      {shipmentPieces.length < 300
        && shipmentType !== ShipmentType.ENVELOPE
        && <div
          className="shipment-type__add-piece"
          onClick={handleAddPiece}
        >
          <Icon name="Close" color="var(--primary)" /> Add Another Piece
        </div>
      }
    </div>
  )
}

export default ShipmentTypeCard;

