import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { ImperialMetric } from "./bookingSlice";

export enum LabelType {
  PDF = 'PDF',
  THERMAL = 'Thermal'
}

export interface SenderContactDetails {
  name: string,
  organisation: string,
  addressLine1: string,
  addressLine2: string,
  cityTown: string,
  countyStateProvince: string,
  countryISO: string,
  postalCode: string,
  telephone: string,
  residential: boolean
}

export interface Manifest {
  csvData: any,
  readyDate: number,
  senderContactDetails: SenderContactDetails,
  labelType: any,
  steps: {
    name: string,
    status: string,
    clickable: boolean
  }[],
  isBookingError: boolean,
  imperialOrMetric: ImperialMetric;
  isInternational: boolean;
  domesticService: any,
  internationalService: any
}

const initialState: Manifest = {
  csvData: [],
  readyDate: 0,
  senderContactDetails: {
    name: '',
    organisation: '',
    addressLine1: '',
    addressLine2: '',
    cityTown: '',
    countyStateProvince: '',
    countryISO: '',
    postalCode: '',
    telephone: '',
    residential: false
  },
  labelType: {title: LabelType.PDF, value: LabelType.PDF},
  steps: [
    {
      name: 'UPLOAD_PAGE',
      status: 'active',
      clickable: false
    },
    {
      name: 'REVIEW_PAGE',
      status: 'active',
      clickable: false
    },
    {
      name: 'OPTIONS_PAGE',
      status: 'todo',
      clickable: false
    },
    {
      name: 'FINISH_PAGE',
      status: 'todo',
      clickable: false
    }
  ],
  isBookingError: false,
  imperialOrMetric: ImperialMetric.METRIC,
  isInternational: false,
  domesticService: {title: "", value: ""},
  internationalService: {title: "", value: ""}
}

export const manifestSlice = createSlice({
  name: 'manifest',
  initialState,
  reducers: {
    saveCsvData(state, action) {
      state.csvData = action.payload;
    },
    saveReadyDate(state, action) {
      state.readyDate = action.payload;
    },
    setSenderContactDetails(state, action) {
      state.senderContactDetails = action.payload;
    },
    setLabelType(state, action) {
      state.labelType = action.payload;
    },
    setDomesticService(state, action) {
      state.domesticService = action.payload;
    },
    setInternationalService(state, action) {
      state.internationalService = action.payload;
    },
    updateSteps(state, action) {
      state.steps = action.payload;
    },
    setBookingErrorStatus(state, action) {
      state.isBookingError = action.payload;
    },
    setImperialOrMetric(state, action) {
      state.imperialOrMetric = action.payload;
    },
    setIsInternational(state, action) {
      state.isInternational = action.payload;
    },
    resetManifestState() {
      return {...initialState}
    }
  },
})

export const manifestSelector = (state: RootState) => state.manifest;
export const manifestActions = manifestSlice.actions;
