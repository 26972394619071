import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { WlBookingHistoryItem } from '../WlBookingsPage-Utils';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { AMITable, Icon } from '../../../../../ui-components/UiComponents';
import getSymbolFromCurrency from 'currency-symbol-map';
import WlBookingModal from './wl-booking-modal/WlBookingModal';

const WlBookingsTable: React.FC<{
  data: WlBookingHistoryItem[];
  groupByCompany: boolean;
}> = ({
  data,
  groupByCompany
}) => {

  const [displayData, setDisplayData] = useState<WlBookingHistoryItem[]>([]);
  const [activeColumn, setActiveColumn] = useState('bookingDate');
  const [sortDirection, setSortDirection] = useState(3);
  const [chosenBooking, setChosenBooking] = useState<WlBookingHistoryItem>();

  const screenWidth = useWindowDimensions().width;

  useEffect(() => {
    const sortedData = data.sort((a,b) => new Date(b.shipmentDate).getTime() - new Date(a.shipmentDate).getTime());
    setDisplayData(sortedData);
  }, [data])

  const resetSort = () => {
    const displayDataCopy = [...displayData];
    displayDataCopy.sort((a,b) => new Date(b.shipmentDate).getTime() - new Date(a.shipmentDate).getTime());
    setSortDirection(1);
    setDisplayData(displayDataCopy);
  }

  const sortByDate = () => {
    setActiveColumn('bookingDate');
    const displayDataCopy = [...displayData];
    if (sortDirection === 1) {
      displayDataCopy.sort((a,b) => new Date(a.shipmentDate).getTime() - new Date(b.shipmentDate).getTime());
      setSortDirection(2);
    } else if (sortDirection === 2) {
      displayDataCopy.sort((a,b) => new Date(b.shipmentDate).getTime() - new Date(a.shipmentDate).getTime());
      setSortDirection(3);
    } else {
      resetSort();
      return;
    }
    setDisplayData(displayDataCopy)
  }

  const sortByYourRef = () => {
    setActiveColumn('yourRef');
    const displayDataCopy = [...displayData];
    if (sortDirection === 1) {
      displayDataCopy.sort((a,b) => a.customerShippingReference?.localeCompare(b.customerShippingReference));
      setSortDirection(2);
    } else if (sortDirection === 2) {
      displayDataCopy.sort((a,b) => b.customerShippingReference?.localeCompare(a.customerShippingReference));
      setSortDirection(3);
    } else {
      resetSort();
      return;
    }
    setDisplayData(displayDataCopy)
  }

  const sortByAMICost = () => {
    setActiveColumn('amiCost');
    const displayDataCopy = [...displayData];
    if (sortDirection === 1) {
      displayDataCopy.sort((a,b) => a.directCustomerCost.value - b.directCustomerCost.value);
      setSortDirection(2);
    } else if (sortDirection === 2) {
      displayDataCopy.sort((a,b) => b.directCustomerCost.value - a.directCustomerCost.value);
      setSortDirection(3);
    } else {
      resetSort();
      return;
    }
    setDisplayData(displayDataCopy)
  }

  const sortByPercentageIncrease = () => {
    setActiveColumn('percentageIncrease');
    const displayDataCopy = [...displayData];
    if (sortDirection === 1) {
      displayDataCopy.sort((a,b) => (a.markupApplied.percentage ? a.markupApplied.markup : 0) - (b.markupApplied.percentage ? b.markupApplied.markup : 0));
      setSortDirection(2);
    } else if (sortDirection === 2) {
      displayDataCopy.sort((a,b) => (b.markupApplied.percentage ? b.markupApplied.markup : 0) - (a.markupApplied.percentage ? a.markupApplied.markup : 0));
      setSortDirection(3);
    } else {
      resetSort();
      return;
    }
    setDisplayData(displayDataCopy)
  }

  const sortByIncreasedAmount = () => {
    setActiveColumn('increasedAmount');
    const displayDataCopy = [...displayData];
    if (sortDirection === 1) {
      displayDataCopy.sort((a,b) => a.markup.value - b.markup.value);
      setSortDirection(2);
    } else if (sortDirection === 2) {
      displayDataCopy.sort((a,b) => b.markup.value - a.markup.value);
      setSortDirection(3);
    } else {
      resetSort();
      return;
    }
    setDisplayData(displayDataCopy)
  }

  const sortByShipperPrice = () => {
    setActiveColumn('shipperPrice');
    const displayDataCopy = [...displayData];
    if (sortDirection === 1) {
      displayDataCopy.sort((a,b) => a.indirectCustomerCost.value - b.indirectCustomerCost.value);
      setSortDirection(2);
    } else if (sortDirection === 2) {
      displayDataCopy.sort((a,b) => b.indirectCustomerCost.value - a.indirectCustomerCost.value);
      setSortDirection(3);
    } else {
      resetSort();
      return;
    }
    setDisplayData(displayDataCopy)
  }


  const downChevron = (
    <Icon
      name="ChevronDown"
      style={{
        width: '12px',
        paddingLeft: '2px',
        transform: 'translate(2px, 6px) scale(1.2)',
        fill: 'var(--primary)',
        position: 'absolute'
      }}
    />
  )

  const upChevron = (
    <Icon
      name="ChevronUp"
      style={{
        width: '12px',
        paddingLeft: '2px',
        fill: 'var(--primary)',
        transform: 'translate(2px, -4px) scale(1.2)',
        position: 'absolute'
      }}
    />
  )

  const ghostChevrons = (
    <div className="ghost-chevrons">
      <Icon
        name="ChevronUp"
        style={{
          width: '12px',
          paddingLeft: '2px',
          fill: 'var(--secondary)',
          transform: 'translate(2px, -4px) scale(1.2)',
          position: 'absolute',
        }}
      />
      <Icon
        name="ChevronDown"
        style={{
          width: '12px',
          paddingLeft: '2px',
          transform: 'translate(2px, 6px) scale(1.2)',
          fill: 'var(--secondary)',
          position: 'absolute',
        }}
      />
    </div>
  )

  const onBookingClick = (booking: WlBookingHistoryItem) => {
    setChosenBooking(booking)
  }

  const [clickTime, setClickTime] = useState(0);

  const onMouseDown = () => {
    setClickTime(Date.now());
  }

  const onMouseUp = (booking: any) => {
    if (Date.now() - clickTime < 400) onBookingClick(booking);
  }

  return (
    <>
      <AMITable className="booking-history-table">
        <thead>
          <tr className="booking-history-table__head-row">
            <th
              onClick={sortByDate}
              style={{paddingRight: '24px'}}
            >
              Booking Date
              {ghostChevrons}
              {activeColumn === 'bookingDate'
                && sortDirection === 3
                && downChevron
              }
              {activeColumn === 'bookingDate'
                && sortDirection === 2
                && upChevron
              }
            </th>

            <th onClick={sortByYourRef}>
              HAWB Ref
              {ghostChevrons}
              {activeColumn === 'yourRef'
                && sortDirection === 3
                && downChevron
              }
              {activeColumn === 'yourRef'
                && sortDirection === 2
                && upChevron
              }
            </th>

            {!groupByCompany && (
              <th>
                Company
              </th>
            )}

            {screenWidth > 740 && (
              <th onClick={sortByAMICost}>
                AMI Cost
                {ghostChevrons}
                {activeColumn === 'amiCost'
                  && sortDirection === 3
                  && downChevron
                }
                {activeColumn === 'amiCost'
                  && sortDirection === 2
                  && upChevron
                }
              </th>
            )}

            {screenWidth > 860 && (
              <th onClick={sortByPercentageIncrease}>
                Percentage Increase
                {ghostChevrons}
                {activeColumn === 'percentageIncrease'
                  && sortDirection === 3
                  && downChevron
                }
                {activeColumn === 'percentageIncrease'
                  && sortDirection === 2
                  && upChevron
                }
              </th>
            )}

            <th onClick={sortByIncreasedAmount}>
              Increased Amount
              {ghostChevrons}
              {activeColumn === 'increasedAmount'
                && sortDirection === 3
                && downChevron
              }
              {activeColumn === 'increasedAmount'
                && sortDirection === 2
                && upChevron
              }
            </th>

            <th onClick={sortByShipperPrice}>
              Shipper Price
              {ghostChevrons}
              {activeColumn === 'shipperPrice'
                && sortDirection === 3
                && downChevron
              }
              {activeColumn === 'shipperPrice'
                && sortDirection === 2
                && upChevron
              }
            </th>
          </tr>
        </thead>
        <tbody>
          {displayData.map((booking: WlBookingHistoryItem, index: number) => {
            return (
              <tr
                key={index}
                onMouseDown={() => onMouseDown()}
                onMouseUp={() => onMouseUp(booking)}
                onClick={() => setChosenBooking(booking)}
              >
                {screenWidth >= 640 && <td>{dayjs(booking.shipmentDate).format('DD/MM/YYYY')}</td>}
                {screenWidth < 640 && <td>{dayjs(booking.shipmentDate).format('DD/MM/YY')}</td>}

                <td>{booking.customerShippingReference}</td>
                {!groupByCompany && <td>{booking.companyName}</td>}
                {screenWidth > 740 && <td>{getSymbolFromCurrency(booking.directCustomerCost.currency)}{booking.directCustomerCost.value}</td>}
                {screenWidth > 860 && <td>{booking.markupApplied.percentage ? booking.markupApplied.markup + "%" : "-"}</td>}
                <td>{getSymbolFromCurrency(booking.markup.currency)}{booking.markup.value}</td>
                <td>{getSymbolFromCurrency(booking.indirectCustomerCost.currency)}{booking.indirectCustomerCost.value}</td>
              </tr>
            )
          })}
        </tbody>
      </AMITable>

      {chosenBooking && (
        <WlBookingModal
          booking={chosenBooking}
          closeModal={() => setChosenBooking(undefined)}
        />
      )}
    </>
  )
}

export default WlBookingsTable;