import React, { useState } from 'react';
import './MobileNavigation.scss';
import { useHistory, useLocation } from "react-router-dom";
import {useAppDispatch} from "../../app/hooks";
import {customerActions} from '../../features/customerSlice';
import { bookingActions } from '../../features/bookingSlice';
import { appDataActions } from '../../features/appDataSlice';
import { Icon } from '../../ui-components/UiComponents';

const MobileNavigation: React.FC<{onNavigationOpen: (event: boolean) => void}> = ({onNavigationOpen}) => {

  const location = useLocation().pathname;

  const history = useHistory();

  const dispatch = useAppDispatch();

  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isMaskVisible, setIsMaskVisible] = useState(false);

  const onAccountMenuClick = () => {
    setIsMaskVisible(true);
    setIsAccountMenuOpen(true);
    onNavigationOpen(true);
  }

  const closeMenu = () => {
    setIsMaskVisible(false);
    setIsAccountMenuOpen(false);
    onNavigationOpen(false);
  }

  const handleLogoutClick = () => {
    dispatch(customerActions.logOut());
    closeMenu();
    history.push('/auth');
  }

  const onNewBookingClick = () => {
    dispatch(bookingActions.resetState());
    dispatch(appDataActions.resetStateWithConditions());
    history.push('/single-booking');
  }

  const onViewAccountClick = () => {
    history.push('/account');
    closeMenu();
  }

  return (
    <>
      <div
        className={isMaskVisible ? "mobile-navigation-mask--visible" : "mobile-navigation-mask--hidden"}
        onClick={closeMenu}
      />
      <div className={
        isAccountMenuOpen ? "mobile-account-menu--open" : "mobile-account-menu--closed"
      }>
        <p
          className="mobile-account-menu__item mobile-account-menu__item"
          onClick={onViewAccountClick}
        >View Account</p>
        <p className="mobile-account-menu__item">
          <a href="https://airmenzies.com/policies/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
        </p>
        <p className="mobile-account-menu__item">
          <a href="/faq" target="_blank" rel="noreferrer">FAQ</a>
        </p>
        <p className="mobile-account-menu__item">
        <a href="/contact" target="_blank" rel="noreferrer">Contact</a>
        </p>
        <p
          className="mobile-account-menu__item mobile-account-menu__item--primary"
          onClick={handleLogoutClick}
        >Logout</p>
      </div>

      <div className="mobile-navigation">
        <div
          className={location === '/single-booking' ? 'mobile-navigation__link--active' : 'mobile-navigation__link'}
          onClick={onNewBookingClick}
        >
          <Icon
            name="Parcel"
            color="var(--textLight)"
          />
          New Booking
        </div>
        <p>|</p>
        <div
          className="mobile-navigation__link"
          onClick={() => history.push('/bulk-booking')}
        >
          <div>
            <Icon
              name="Parcel"
              color="var(--textLight)"
            />
            <Icon
              name="Parcel"
              color="var(--textLight)"
            />
          </div>
          Bulk Booking
        </div>
        <p>|</p>
        <div
          className={location === '/tracking' ? "mobile-navigation__link--active" : "mobile-navigation__link"}
          onClick={() => history.push('/tracking')}
        >
          <Icon
            name="Geo"
            color="var(--textLight)"
          />
          Tracking
        </div>

        <p>|</p>
        <div
          className="mobile-navigation__link"
          onClick={onAccountMenuClick}
        >
          <Icon
            name="Hamburger"
            color="var(--textLight)"
          />
          Menu
        </div>
      </div>
    </>
  )
}

export default MobileNavigation;
