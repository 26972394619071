import React, { useEffect, useState } from 'react';
import './ShipperDetails.scss';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  AMICheckbox,
  AMIFormElement,
  AMIInput,
  AMIPopover,
  AMISelect,
  Icon
} from '../../../../ui-components/UiComponents';
import TaxNumber from '../../../../components/tax-numbers/TaxNumbers';
import { appDataActions, appDataSelector } from '../../../../features/appDataSlice';
import { bookingActions, bookingSelector} from '../../../../features/bookingSlice';
import { formatCounties, getCarrierName, getPostalCodeRegex, ServiceType, trimTelephone } from '../../../../utilities/HelperUtilities';
import {extractRule, getTradeRoute, isIntraEu, TradeRoute} from '../../../../utilities/RulesEngineUtilities';
import {getCountryInfo} from '../../../../utilities/APIUtilities';
import {customerDetailsSelector} from '../../../../features/customerSlice';
import {retrieveContent} from "../../../../utilities/ContentRetrieverUtilities";
import SavedAddressSelect, { DetailSection } from '../../../../components/saved-address-select/SavedAddressSelect';
import { compareAddresses } from '../utilities';

const ShipperDetails: React.FC<{
  errHandler: any;
  customRules: any;
}> = ({
  errHandler,
  customRules
}) => {

  const dispatch = useAppDispatch();
  const booking = useAppSelector(bookingSelector);
  const customer = useAppSelector(customerDetailsSelector);
  const appData = useAppSelector(appDataSelector);
  const showErrors = appData.showDetailsPageErrors;
  const taxNumbers = booking.shipperDetails.taxNumbers;
  const counties = appData.shipperCounties;
  const tradeRoute = getTradeRoute(customer, booking);
  const branchId = customer.creditCheck.branchId;
  const isInternational = getTradeRoute(customer, booking) !== TradeRoute.DOMESTIC;
  const selectedQuote = booking.selectedQuote;
  const apiConfig = appData.apiConfig;
  const bookingIsIntraEU :boolean = isIntraEu(booking);

  const [aesItnText, setAesItnText] = useState<any>('AES/ITN or exemption code');
  const [aesPopoverVisible, setAesPopoverVisible] = useState(false);
  const [savedAddressSnapshot, setSavedAddressSnapshot] = useState<any>();
  const [disableSaveAddressCheckbox, setDisableSavedAddressCheckbox] = useState(false);
  const [carrier, setCarrier] = useState("");

  useEffect(() => {
    setCarrier(getCarrierName(selectedQuote.quoteId));
  }, [selectedQuote]);

  useEffect(() => {
    setDisableSavedAddressCheckbox(compareAddresses(
      booking.shipperDetails,
      booking.shipperDetails.country,
      customer.creditCheck.branchId,
      savedAddressSnapshot
    ))
  }, [booking.shipperDetails]);

  const handleTrueShipperCheck = () => {
    dispatch(bookingActions.updateField({
      field: 'trueShipper',
      value: !booking.trueShipper
    }))
  }

  const handleCountryChange = async(countryCode: string, address?: any) => {
    const countryInfo = await getCountryInfo(apiConfig, countryCode);

    const regex = getPostalCodeRegex(countryInfo);

    dispatch(appDataActions.updateField({
      field: 'shipperPostalCodeRegex',
      value: regex
    }))

    if (countryInfo.countiesStates.length > 0) {
      const formattedCounties = formatCounties(countryInfo.countiesStates);
      dispatch(appDataActions.updateField({
        field: 'shipperCounties',
        value: formattedCounties
      }))
    } else {
      dispatch(appDataActions.updateField({
        field: 'shipperCounties',
        value: []
      }))
    }

    if (address) {
      if (address.countyStateProvince) {
        dispatch(bookingActions.updateShipperDetail({
          field: 'countyStateProvince',
          value: address.countyStateProvince
        }))
      } else {
        dispatch(bookingActions.updateShipperDetail({
          field: 'countyStateProvince',
          value: ''
        }))
      }
    }
  }

  const changeShipperDetails = (field: string, event: any) => {
    let value = event.value;
    if (field === 'telephoneNumber') {
      value = trimTelephone(value);
    }
    if (field === 'country') {
      value = event;
      handleCountryChange(event.value);
    }
    dispatch(bookingActions.updateShipperDetail({
      field,
      value
    }))
  }

  useEffect(() => {
    retrieveContent('details-aesItn', booking.origin.value, customer)
      .then((result: any) => {
        result && setAesItnText(result)
      })
      .catch(error => console.error(error));
  }, [])

  const handleAddTaxNumber = () => {
    dispatch(bookingActions.addShipperTaxNumber(
      {
        type: '',
        value: ''
      }
    ));
  }

  const updateTaxNumbers = (event: any) => {
    dispatch(bookingActions.updateShipperTaxNumbers(
      [...event]
    ));
  }

  const deleteTaxNumber = (index: number) => {
    if (taxNumbers.length > 1) {
      const taxNumbersCopy = [...taxNumbers];
      taxNumbersCopy.splice(index, 1);
      dispatch(bookingActions.updateShipperTaxNumbers(taxNumbersCopy));
    }
  }

  const onSaveAddressCheck = (address: any) => {
    handleCountryChange(address.country.value, address);
    setSavedAddressSnapshot(address);
  }

  useEffect(() => {
    if (extractRule(customRules, 'noEEIExemption')?.value) {
      dispatch(bookingActions.setAES('NO EEI 30.36'))
    } else if (booking.shipperDetails.exportComplianceStatement.aes === 'NO EEI 30.36'){
      dispatch(bookingActions.setAES(''))
    }
  }, [customRules]);

  useEffect(() => {
    handleCountryChange(booking.shipperDetails.country.value);
  }, [])

  const onEdnChange = (event: any) => {
    dispatch(bookingActions.setEDN(event.target.value));
  }

  const onCCNChange = (event: any) => {
    dispatch(bookingActions.setCCN(event.target.value));
  }

  const onGenerateECSCheck = (event: any, checked: boolean) => {
    dispatch(bookingActions.updateShipperDetail({field: 'generateECS', value: checked}));
  }

  const [ednRequired, setEdnRequired] = useState<boolean>(false);
  const [ccnRequired, setCCNRequired] = useState<boolean>(false);


  useEffect(() => {
    if (extractRule(customRules, 'exportDeclarationNumber')?.value) setEdnRequired(true)
    else setEdnRequired(false)

    if (extractRule(customRules, 'customerCustomsNumber')?.value) setCCNRequired(true)
    else setCCNRequired(false)

  }, [customRules])

  return (
    <div
      className="shipper-details horizontal-card"
      id="shipper-details"
    >

      <p className="horizontal-card__title">Shipper Details</p>

      {selectedQuote.serviceType === ServiceType.COLLECTED
        && carrier !== "ups"
      && (
        <AMICheckbox
          text="I confirm that the collection details provided above are those of the true shipper of this consignment."
          className="details-page__checkbox"
          checked={booking.trueShipper}
          onChange={handleTrueShipperCheck}
        />
      )}

      {!booking.trueShipper && <>

      <SavedAddressSelect
        section={DetailSection.SHIPPER_DETAILS}
        filters={selectedQuote.serviceType === ServiceType.DROP_IN ? ["postalCode"] : []}
        onChange={(event: any) => onSaveAddressCheck(event)}
      ></SavedAddressSelect>

      <AMIFormElement
        label="Full Contact Name"
        errorMessage={
          showErrors
          && !errHandler.shipperDetails.contactName.criteria
          ? errHandler.shipperDetails.contactName.message
          : ''
        }
      >
        <AMIInput
          name="shipperContactName"
          placeholder="Required"
          size="large"
          value={booking.shipperDetails.contactName}
          onChange={(event) => changeShipperDetails('contactName', event.target)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Company Name"
        errorMessage={
          showErrors
          && !errHandler.shipperDetails.companyName.criteria
          ? errHandler.shipperDetails.companyName.message
          : ''
        }
      >
        <AMIInput
          name="shipperCompanyName"
          placeholder="Required"
          size="large"
          value={booking.shipperDetails.companyName}
          onChange={(event) => changeShipperDetails('companyName', event.target)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Country"
        className="details-page__country"
        errorMessage={
          showErrors
          && !errHandler.shipperDetails.country.criteria
          ? errHandler.shipperDetails.country.message
          : ''
        }
      >
        <AMISelect
          name="shipperCountry"
          placeholder="Required"
          size="large"
          isSearchable
          style={{
            width: '100%',
          }}
          defaultValue={booking.shipperDetails.country}
          options={appData.countries}
          disabled={selectedQuote.serviceType === ServiceType.DROP_IN}
          onChange={(event) => changeShipperDetails('country', event)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Address Line 1"
        errorMessage={
          showErrors
          && !errHandler.shipperDetails.addressLine1.criteria
          ? errHandler.shipperDetails.addressLine1.message
          : ''
        }
      >
        <AMIInput
          name="shipperAddressLine1"
          placeholder="Required"
          size="large"
          value={booking.shipperDetails.addressLine1}
          onChange={(event) => changeShipperDetails('addressLine1', event.target)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Address Line 2"
        errorMessage={
          showErrors
          && !errHandler.shipperDetails.addressLine2.criteria
          ? errHandler.shipperDetails.addressLine2.message
          : ''
        }
      >
        <AMIInput
          name="shipperAddressLine2"
          size="large"
          value={booking.shipperDetails.addressLine2}
          onChange={(event) => changeShipperDetails('addressLine2', event.target)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Address Line 3"
        errorMessage={
          showErrors
          && !errHandler.shipperDetails.addressLine3.criteria
          ? errHandler.shipperDetails.addressLine3.message
          : ''
        }
      >
        <AMIInput
          name="shipperAddressLine3"
          size="large"
          value={booking.shipperDetails.addressLine3}
          onChange={(event) => changeShipperDetails('addressLine3', event.target)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="City/Town"
        errorMessage={
          showErrors
          && !errHandler.shipperDetails.cityTown.criteria
          ? errHandler.shipperDetails.cityTown.message
          : ''
        }
      >
        <AMIInput
          name="shipperCityTown"
          placeholder="Required"
          size="large"
          value={booking.shipperDetails.cityTown}
          disabled={selectedQuote.serviceType === ServiceType.DROP_IN}
          onChange={(event) => changeShipperDetails('cityTown', event.target)}
        />
      </AMIFormElement>

      {counties.length === 0
        && !extractRule(customRules, 'upsCollCountyStateHidden')?.value
        && (
        <AMIFormElement
          label="County/State/Province"
          errorMessage={
            showErrors
            && !errHandler.shipperDetails.countyStateProvince.criteria
            ? errHandler.shipperDetails.countyStateProvince.message
            : ''
          }
        >
          <AMIInput
            name="shipperCountyStateProvince"
            size="large"
            value={booking.shipperDetails.countyStateProvince}
            onChange={(event) => changeShipperDetails('countyStateProvince', event.target)}
          />
        </AMIFormElement>
      )}

      {counties.length > 0
        && !extractRule(customRules, 'upsCollCountyStateHidden')?.value
        && (
        <AMIFormElement
          label="County/State/Province"
          errorMessage={
            showErrors
            && !errHandler.shipperDetails.countyStateProvince.criteria
            ? errHandler.shipperDetails.countyStateProvince.message
            : ''
          }
        >
          <AMISelect
            name="shipperCountyStateProvince"
            placeholder="Required"
            size="large"
            isSearchable
            options={counties}
            onChange={changeShipperDetails.bind(null, 'countyStateProvince')}
            defaultValue={counties.find((item: any) => {
              return item.value === booking.shipperDetails.countyStateProvince
            })}
          />
        </AMIFormElement>
      )}
      <AMIFormElement
        label="Postal/Zip Code"
        errorMessage={
          showErrors
          && appData.shipperPostalCodeRegex
          && !errHandler.shipperDetails.postalCode.criteria
            ? errHandler.shipperDetails.postalCode.message
            : ''
        }
      >
        <AMIInput
          name="collectionPostalCode"
          placeholder={appData.shipperPostalCodeRegex ? "Required" : ""}
          size="large"
          value={booking.shipperDetails.postalCode}
          disabled={selectedQuote.serviceType === ServiceType.DROP_IN}
          onChange={(event) => changeShipperDetails('postalCode', event.target)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Telephone Number"
        errorMessage={
          showErrors
          && !errHandler.shipperDetails.telephoneNumber.criteria
          ? errHandler.shipperDetails.telephoneNumber.message
          : ''
        }
      >
        <AMIInput
          name="shipperTelephoneNumber"
          placeholder="Required"
          size="large"
          value={booking.shipperDetails.telephoneNumber}
          onChange={(event) => changeShipperDetails('telephoneNumber', event.target)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Email"
        errorMessage={
          showErrors
          && !errHandler.shipperDetails.email.criteria
          ? errHandler.shipperDetails.email.message
          : ''
        }
      >
        <AMIInput
          name="shipperEmail"
          placeholder="Required"
          size="large"
          type="email"
          value={booking.shipperDetails.email}
          onChange={(event) => changeShipperDetails('email', event.target)}
        />
      </AMIFormElement>
      </>
      }

      {isInternational && !bookingIsIntraEU && taxNumbers.map((element: any, index: number) => {
        return (
          <div className="tax-numbers-wrapper" key={index}>
            <TaxNumber
              element={element}
              index={index}
              allElements={taxNumbers}
              booking={booking}
              detailsContainer="shipperDetails"
              customRules={customRules}
              updateElements={(event) => updateTaxNumbers(event)}
              onDelete={() => deleteTaxNumber(index)}
            />
          </div>
        )
      })}

      {isInternational && <p className="tax-numbers-add-text" onClick={handleAddTaxNumber}>+ Add another tax number</p>}

      {!errHandler.shipperDetails.taxNumbers.criteria
        && showErrors
        && <p className="tax-number-error-message">EORI number must be supplied for this shipment</p>
      }

      {extractRule(customRules, 'aesItnVisible')?.value
      && <AMIFormElement
        label={(
          <div>
            {aesItnText} {extractRule(appData.customRules, 'b13ALabelText')?.value
              ? extractRule(appData.customRules, 'b13ALabelText')?.passValue
              : ""
            }
            <span
              onMouseEnter={() => setAesPopoverVisible(true)}
              onMouseLeave={() => setAesPopoverVisible(false)}
            >
              <Icon
                name="CircleQuestion"
                color="var(--primary)"
                style={{marginLeft: '4px'}}
              />
              <AMIPopover
                isVisible={aesPopoverVisible}
              >
                {booking.origin.value !== "CA" ?
                  <div>
                    <p style={{marginBottom: '12px'}}>AES numbers are to be entered in the format AESXyyyymmddxxxxxx - Example: <strong>AESX2022011412345678</strong></p>
                    <p>Exemption codes are to be entered in the format No EEI xx.xx(x)(x) - example: <strong>No EEI 30.37(a)</strong></p>
                  </div>
                  :
                  <div>
                    <p>Proof of Reporting numbers are to be entered as 23 alphanumeric characters only. No special characters will be allowed.</p>
                  </div>
                }
              </AMIPopover>
            </span>
          </div>
        )}
        errorMessage={
          showErrors
          && !errHandler.shipperDetails.aes.criteria
            ? errHandler.shipperDetails.aes.message
            : ''
        }
      >
        <AMIInput
          name="shipperAesNumber"
          placeholder={extractRule(customRules, 'aesItn')?.value
          && !extractRule(customRules, 'noEEIExemption')?.value
            ? "Required"
            : ""}
          size="large"
          disabled={extractRule(customRules, 'noEEIExemption')?.value}
          value={booking.shipperDetails.exportComplianceStatement.aes}
          onChange={(event) => {
            dispatch(bookingActions.setAES(event.target.value));
          }}

        />
      </AMIFormElement>
      }

      {
        tradeRoute === TradeRoute.EXPORT
        && booking.origin.value === 'AU'
        && (
          <>
            <AMIFormElement
              label="Export Declaration Number (EDN)"
              errorMessage={
                showErrors
                && !errHandler.shipperDetails.edn.criteria
                  ? errHandler.shipperDetails.edn.message
                  : ""
              }
            >
              <AMIInput
                name="EDN"
                size="large"
                placeholder={ednRequired && !booking.shipperDetails.generateECS ? "Required": ""}
                disabled={booking.shipperDetails.generateECS}
                value={booking.shipperDetails.generateECS ? "" : booking.shipperDetails.exportComplianceStatement.edn}
                onChange={onEdnChange}
              />
            </AMIFormElement>

            {ednRequired && (
              <AMIFormElement>
                <AMICheckbox
                  text="Generate Export Declaration Number (EDN)?"
                  className="edn-checkbox"
                  checked={booking.shipperDetails.generateECS}
                  onChange={onGenerateECSCheck}
                />
              </AMIFormElement>
            )}

            {ednRequired && <p className="edn-description">If you do not have the EDN available, please check the &quot;Generate Export Declaration Number&quot; option and customer services will generate one for you at a charge of AUD$22.50.</p>}
          </>
        )
      }

      {
        tradeRoute === TradeRoute.EXPORT
        && booking.origin.value === 'NZ'
        && customer.countryOfResidence.value === 'NZ'
        && (
          <>
            <AMIFormElement
              label="Customer Customs Number (CCN)"
              errorMessage={
                showErrors
                && !errHandler.shipperDetails.ccn.criteria
                  ? errHandler.shipperDetails.ccn.message
                  : ""
              }
            >
              <AMIInput
                name="CCN"
                size="large"
                placeholder={ccnRequired && !booking.shipperDetails.generateECS ? "Required": ""}
                disabled={booking.shipperDetails.generateECS}
                value={booking.shipperDetails.generateECS ? "" : booking.shipperDetails.exportComplianceStatement.ccn}
                onChange={onCCNChange}
              />
            </AMIFormElement>

            {ccnRequired && (
              <AMIFormElement>
                <AMICheckbox
                  text="Generate Customer Customs Number (CCN)?"
                  className="ccn-checkbox"
                  checked={booking.shipperDetails.generateECS}
                  onChange={onGenerateECSCheck}
                />
              </AMIFormElement>
            )}

            {ccnRequired && <p className="ccn-description">If you do not have the CCN available, please check the &quot;Generate Customer Customs Number&quot; option and customer services will generate one for you at a charge of NZD$17.94.</p>}
          </>
        )
      }

      {!booking.trueShipper && (
        <AMIFormElement className="save-address__checkbox">
          <AMICheckbox
            text=""
            disabled={disableSaveAddressCheckbox || !branchId}
            checked={appData.saveShipperAddress}
            onChange={() => dispatch(appDataActions.setShipperSaveAddressCheckbox())}
          >
            {branchId && <p>Save this address for future use?</p>}
            {!branchId && <p>Save this address for future use? (Please contact <a href="/contact" target="_blank" rel="noreferrer">customer services</a> to enable address book features)</p>}
          </AMICheckbox>
        </AMIFormElement>
      )}

    </div>
  )

}

export default ShipperDetails;