import {
  AddressType,
  ElectronicTradeDocumentFormatType,
  ElectronicTradeDocumentType,
  ImperialMetric,
  initialState,
  ReasonForExport,
  ShipmentType } from "../features/bookingSlice";
import { PackageLocation } from "./APIUtilities";
import dayjs from "dayjs";

export const templates = [
  {
    templateName: 'Clear',
    data: initialState
  },
  {
    templateName: 'Iain - US-POL docs 1000 coll',
    data: {
      origin: {
        title: 'United States',
        value: 'US',
        tradeBloc: 'USMCA'
      },
      originCityTown: 'Inglewood',
      originPostalCode: '90310',
      destination: {
        title: 'Poland',
        value: 'PL',
        tradeBloc: 'EUCU'
      },
      originResidential: false,
      destinationCityTown: 'Warsaw',
      destinationPostalCode: '00-071',
      destinationResidential: false,
      readyDate: 0,
      shipmentType: ShipmentType.DOCS,
      pieces: [
        {
          weight: '5',
          length: '10',
          width: '10',
          height: '10'
        }
      ],
      imperialMetric: 'METRIC',
      totalShipmentValue: '1000',
      preferredCurrency: {
        title: '$ USD',
        value: 'USD',
        symbol: '$'
      },
      quotes: [],
      selectedQuote: null,
      insure: false,
      hawb: 'hawb4546',
      contentDescription: 'Clothes',
      countryOfOrigin: {
        title: 'United States',
        value: 'US'
      },
      reasonForExport: ReasonForExport.SOLD,
      collectionDetails: {
        pickUpFrom: '06:00:00',
        pickUpTo: '19:00:00',
        contactName: 'Contact Name',
        companyName: 'Company Name',
        addressType: AddressType.BUSINESS,
        packageLocation: PackageLocation.NONE,
        addressLine1: 'Address 1',
        addressLine2: '',
        countyStateProvince: 'CA',
        cityTown: 'Inglewood',
        postalCode: '90310',
        telephoneNumber: '1234567891',
        email: 'test@test.com',
        additionalInfo: ''
      },
      dropInDetails: {
        id: '',
        openFrom: '',
        openTo: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        countyStateProvince: '',
        cityTown: '',
        postalCode: '',
        countryCode: '',
        telephoneNumber: ''
      },
      shipperDetails: {
        contactName: 'Contact name',
        companyName: 'Company Name',
        addressLine1: 'Address 1',
        addressLine2: 'Address 2',
        countyStateProvince: 'CA',
        cityTown: 'Inglewood',
        postalCode: '90310',
        country: {
          title: 'United States',
          value: 'US',
          tradeBloc: 'USMCA'
        },
        telephoneNumber: '0123456789',
        email: 'test@test.com.com',
        taxNumbers: [
          {
            type: '',
            value: ''
          }
        ],
        aes: ''
      },
      trueShipper: true,
      consigneeDetails: {
        contactName: 'Contact Name',
        companyName: 'Company Name',
        addressType: AddressType.BUSINESS,
        addressLine1: 'Address 1',
        addressLine2: '',
        countyStateProvince: '',
        cityTown: 'Warsaw',
        postalCode: '00-071',
        telephoneNumber: '0180360715',
        email: 'test@test.com',
        sendEmail: false,
        taxNumbers: [
          {
            type: 'EORI',
            value: '3562524764'
          }
        ],
        additionalInfo: ''
      },
      brokerDetails: {
        brokerSelected: false,
        contactName: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        countyStateProvince: '',
        cityTown: '',
        country: {
          title: '',
          value: ''
        },
        postalCode: '',
        telephoneNumber: '',
        email: '',
        taxNumbers: [
          {
            type: '',
            value: ''
          }
        ]
      },
      preferences: {
        email: 'iain.mcclafferty@airmenzies.com',
        labelType: 'PDF'
      },
      termsCheck: true,
      dangerousGoodsCheck: true,
      courierInfoCheck: true,
      specialService: [],
      customsDetails: {
        amount: {
          value: '',
          currency: ''
        },
        invoiceDetails: {
          declarationNotes: '',
          declarationStatementRequested: {
            value: false,
            type: ''
          },
          termsOfSale: 'DAP',
          taxDetails: [
            {
              type: '',
              party: '',
              value: ''
            }
          ],
          exportReason: '',
          specialInstructions: '',
          cpc: ''
        },
        electronicTradeDetails: {
          electronicTradeDocumentType: ElectronicTradeDocumentType.UNKNOWN,
          electronicTradeDocumentFormat: ElectronicTradeDocumentFormatType.JPEG,
          electronicTradeDocumentContent: '',
          electronicTradeDocumentName: ''
        },
        importerOfRecordDetails: {
          deliveryAddress: false,
          contactDetails: {
            name: '',
            telephone: ''
          },
          address: {
            organisation: '',
            countryCode: '',
            postalCode: '',
            streetLines: [
              '',
              ''
            ],
            city: '',
            stateOrCounty: ''
          }
        },
        commodities: [
          {
            commodityCode: '4015',
            description: 'T-Shirts clothing',
            countryOfOrigin: '',
            quantity: {
              value: '10',
              unit: 'PCS'
            },
            unitPrice: {
              value: '100',
              currency: 'USD'
            },
            weight: {
              value: '5',
              unit: 'KG'
            }
          },
        ]
      },
      igBookingErrors: [],
      mappedPieces: [],
      ddp: false
    }
  },  
  {
    templateName: 'Iain - US-CA nondoc 5000 coll',
    data: {
      origin: {
        title: 'United States',
        value: 'US',
        tradeBloc: 'USMCA'
      },
      originCityTown: 'Inglewood',
      originPostalCode: '90310',
      destination: {
        title: 'Canada',
        value: 'CA',
        tradeBloc: ''
      },
      originResidential: false,
      destinationCityTown: 'Toronto',
      destinationPostalCode: 'M3C 1T3',
      destinationResidential: false,
      readyDate: 0,
      shipmentType: ShipmentType.NON_DOCS,
      pieces: [
        {
          weight: '10',
          length: '10',
          width: '10',
          height: '10'
        },
        {
          weight: '10',
          length: '10',
          width: '10',
          height: '10'
        }
      ],
      imperialMetric: 'IMPERIAL',
      totalShipmentValue: '5000',
      preferredCurrency: {
        title: '$ USD',
        value: 'USD',
        symbol: '$'
      },
      quotes: [],
      selectedQuote: null,
      insure: false,
      hawb: 'hawb4546',
      contentDescription: 'Clothes',
      countryOfOrigin: {
        title: 'United States',
        value: 'US'
      },
      reasonForExport: ReasonForExport.SOLD,
      collectionDetails: {
        pickUpFrom: '06:00:00',
        pickUpTo: '19:00:00',
        contactName: 'Contact Name',
        companyName: 'Company Name',
        addressType: AddressType.BUSINESS,
        packageLocation: PackageLocation.NONE,
        addressLine1: 'Address 1',
        addressLine2: '',
        countyStateProvince: 'CA',
        cityTown: 'Inglewood',
        postalCode: '90310',
        telephoneNumber: '1234567891',
        email: 'test@test.com',
        additionalInfo: ''
      },
      dropInDetails: {
        id: '',
        openFrom: '',
        openTo: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        countyStateProvince: '',
        cityTown: '',
        postalCode: '',
        countryCode: '',
        telephoneNumber: ''
      },
      shipperDetails: {
        contactName: 'Contact name',
        companyName: 'Company Name',
        addressLine1: 'Address 1',
        addressLine2: 'Address 2',
        countyStateProvince: 'CA',
        cityTown: 'Inglewood',
        postalCode: '90310',
        country: {
          title: 'United States',
          value: 'US',
          tradeBloc: 'USMCA'
        },
        telephoneNumber: '0123456789',
        email: 'test@test.com.com',
        taxNumbers: [
          {
            type: '',
            value: ''
          }
        ],
        aes: ''
      },
      trueShipper: true,
      consigneeDetails: {
        contactName: 'Contact Name',
        companyName: 'Company Name',
        addressType: AddressType.BUSINESS,
        addressLine1: 'Address 1',
        addressLine2: '',
        countyStateProvince: 'ON',
        cityTown: 'Toronto',
        postalCode: 'M3C1T3',
        telephoneNumber: '0180360715',
        email: 'test@test.com',
        sendEmail: false,
        taxNumbers: [
          {
            type: '',
            value: ''
          }
        ],
        additionalInfo: ''
      },
      brokerDetails: {
        brokerSelected: false,
        contactName: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        countyStateProvince: '',
        cityTown: '',
        country: {
          title: '',
          value: ''
        },
        postalCode: '',
        telephoneNumber: '',
        email: '',
        taxNumbers: [
          {
            type: '',
            value: ''
          }
        ]
      },
      preferences: {
        email: 'iain.mcclafferty@airmenzies.com',
        labelType: 'PDF'
      },
      termsCheck: true,
      dangerousGoodsCheck: true,
      courierInfoCheck: true,
      specialService: [],
      customsDetails: {
        amount: {
          value: '',
          currency: ''
        },
        invoiceDetails: {
          declarationNotes: '',
          declarationStatementRequested: {
            value: false,
            type: ''
          },
          termsOfSale: 'DAP',
          taxDetails: [
            {
              type: '',
              party: '',
              value: ''
            }
          ],
          exportReason: '',
          specialInstructions: '',
          cpc: ''
        },
        electronicTradeDetails: {
          electronicTradeDocumentType: ElectronicTradeDocumentType.UNKNOWN,
          electronicTradeDocumentFormat: ElectronicTradeDocumentFormatType.JPEG,
          electronicTradeDocumentContent: '',
          electronicTradeDocumentName: ''
        },
        importerOfRecordDetails: {
          deliveryAddress: false,
          contactDetails: {
            name: '',
            telephone: ''
          },
          address: {
            organisation: '',
            countryCode: '',
            postalCode: '',
            streetLines: [
              '',
              ''
            ],
            city: '',
            stateOrCounty: ''
          }
        },
        commodities: [
          {
            commodityCode: '4015',
            description: 'T-Shirts clothing',
            countryOfOrigin: '',
            quantity: {
              value: '10',
              unit: 'PCS'
            },
            unitPrice: {
              value: '500',
              currency: 'USD'
            },
            weight: {
              value: '10',
              unit: 'LB'
            }
          },
        ]
      },
      igBookingErrors: [],
      mappedPieces: [],
      ddp: false
    }
  },  
  {
    templateName: 'Iain - US-FR nondoc 1000 coll',
    data: {
      origin: {
        title: 'United States',
        value: 'US',
        tradeBloc: 'USMCA'
      },
      originCityTown: 'Inglewood',
      originPostalCode: '90310',
      destination: {
        title: 'France',
        value: 'FR',
        tradeBloc: 'EUCU'
      },
      originResidential: false,
      destinationCityTown: 'Marseille',
      destinationPostalCode: '13004',
      destinationResidential: false,
      readyDate: 0,
      shipmentType: ShipmentType.NON_DOCS,
      pieces: [
        {
          weight: '2',
          length: '2',
          width: '2',
          height: '2'
        }
      ],
      imperialMetric: 'IMPERIAL',
      totalShipmentValue: '1000',
      preferredCurrency: {
        title: '$ USD',
        value: 'USD',
        symbol: '$'
      },
      quotes: [],
      selectedQuote: null,
      insure: false,
      hawb: 'hawb4546',
      contentDescription: 'Clothes',
      countryOfOrigin: {
        title: 'United States',
        value: 'US'
      },
      reasonForExport: ReasonForExport.GIFT,
      collectionDetails: {
        pickUpFrom: '06:00:00',
        pickUpTo: '19:00:00',
        contactName: 'Contact Name',
        companyName: 'Company Name',
        addressType: AddressType.BUSINESS,
        packageLocation: PackageLocation.NONE,
        addressLine1: 'Address 1',
        addressLine2: '',
        countyStateProvince: 'CA',
        cityTown: 'Inglewood',
        postalCode: '90310',
        telephoneNumber: '1234567891',
        email: 'test@test.com',
        additionalInfo: ''
      },
      dropInDetails: {
        id: '',
        openFrom: '',
        openTo: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        countyStateProvince: '',
        cityTown: '',
        postalCode: '',
        countryCode: '',
        telephoneNumber: ''
      },
      shipperDetails: {
        contactName: 'Contact name',
        companyName: 'Company Name',
        addressLine1: 'Address 1',
        addressLine2: 'Address 2',
        countyStateProvince: 'CA',
        cityTown: 'Inglewood',
        postalCode: '90310',
        country: {
          title: 'United States',
          value: 'US',
          tradeBloc: 'USMCA'
        },
        telephoneNumber: '0123456789',
        email: 'test@test.com.com',
        taxNumbers: [
          {
            type: '',
            value: ''
          }
        ],
        aes: ''
      },
      trueShipper: true,
      consigneeDetails: {
        contactName: 'Contact Name',
        companyName: 'Company Name',
        addressType: AddressType.BUSINESS,
        addressLine1: 'Address 1',
        addressLine2: '',
        countyStateProvince: '',
        cityTown: 'Marseille',
        postalCode: '13004',
        telephoneNumber: '0180360715',
        email: 'test@test.com',
        sendEmail: false,
        taxNumbers: [
          {
            type: 'EORI',
            value: '3562524764'
          }
        ],
        additionalInfo: ''
      },
      brokerDetails: {
        brokerSelected: false,
        contactName: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        countyStateProvince: '',
        cityTown: '',
        country: {
          title: '',
          value: ''
        },
        postalCode: '',
        telephoneNumber: '',
        email: '',
        taxNumbers: [
          {
            type: '',
            value: ''
          }
        ]
      },
      preferences: {
        email: 'iain.mcclafferty@airmenzies.com',
        labelType: 'PDF'
      },
      termsCheck: true,
      dangerousGoodsCheck: true,
      courierInfoCheck: true,
      specialService: [],
      customsDetails: {
        amount: {
          value: '',
          currency: ''
        },
        invoiceDetails: {
          declarationNotes: '',
          declarationStatementRequested: {
            value: false,
            type: ''
          },
          termsOfSale: 'DAP',
          taxDetails: [
            {
              type: '',
              party: '',
              value: ''
            }
          ],
          exportReason: '',
          specialInstructions: '',
          cpc: ''
        },
        electronicTradeDetails: {
          electronicTradeDocumentType: ElectronicTradeDocumentType.UNKNOWN,
          electronicTradeDocumentFormat: ElectronicTradeDocumentFormatType.JPEG,
          electronicTradeDocumentContent: '',
          electronicTradeDocumentName: ''
        },
        importerOfRecordDetails: {
          deliveryAddress: false,
          contactDetails: {
            name: '',
            telephone: ''
          },
          address: {
            organisation: '',
            countryCode: '',
            postalCode: '',
            streetLines: [
              '',
              ''
            ],
            city: '',
            stateOrCounty: ''
          }
        },
        commodities: [
          {
            commodityCode: '4015',
            description: 'Clothes',
            countryOfOrigin: '',
            quantity: {
              value: '2',
              unit: 'PCS'
            },
            unitPrice: {
              value: '500',
              currency: 'USD'
            },
            weight: {
              value: '2',
              unit: 'LB'
            }
          }
        ]
      },
      igBookingErrors: [],
      mappedPieces: [
        {
          dimensions: {
            length: 2,
            width: 2,
            height: 2,
            unit: 'IN'
          },
          weight: {
            value: 1000,
            unit: 'LB'
          },
          description: ''
        }
      ],
      ddp: false
    }
  },  
  {
    templateName: 'Craig - US-UK nondoc 5000 di',
    data: {
      origin: {
        title: 'United States',
        value: 'US'
      },
      originCityTown: 'seattle',
      originPostalCode: '98108',
      destination: {
        title: 'United Kingdom',
        value: 'GB',
        tradeBloc: ''
      },
      originResidential: false,
      destinationCityTown: 'torquay',
      destinationPostalCode: 'Tq2 6DD',
      destinationResidential: false,
      readyDate: 0,
      shipmentType: ShipmentType.NON_DOCS,
      pieces: [
        {
          weight: '10',
          length: '10',
          width: '10',
          height: '10'
        }
      ],
      imperialMetric: 'IMPERIAL',
      totalShipmentValue: '5000',
      preferredCurrency: {
        title: '$ USD',
        value: 'USD',
        symbol: '$'
      },
      quotes: [],
      selectedQuote: null,
      insure: false,
      hawb: 'hawb4546',
      contentDescription: 'box of things',
      countryOfOrigin: {
        title: 'United States',
        value: 'US'
      },
      reasonForExport: ReasonForExport.SOLD,
      collectionDetails: {
        pickUpFrom: '',
        pickUpTo: '',
        contactName: '',
        companyName: '',
        addressType: AddressType.BUSINESS,
        packageLocation: PackageLocation.NONE,
        addressLine1: '',
        addressLine2: '',
        countyStateProvince: '',
        cityTown: '',
        postalCode: '',
        telephoneNumber: '',
        email: '',
        additionalInfo: ''
      },
      dropInDetails: {
        id: 56068,
        companyName: 'FedEx Drop Box',
        addressLine1: '1001 SW Klickitat Way',
        countyStateProvince: 'WA',
        cityTown: 'Seattle',
        postalCode: '98134',
        countryCode: 'US',
        telephoneNumber: ''
      },
      shipperDetails: {
        contactName: 'contact name',
        companyName: 'company name',
        addressLine1: 'ADD 1',
        addressLine2: 'ADD 2',
        countyStateProvince: 'WA',
        cityTown: 'Seattle',
        postalCode: '98108',
        country: {
          title: 'United States',
          value: 'US',
          tradeBloc: 'USMCA'
        },
        telephoneNumber: '0123456789',
        email: 'c@c.com',
        taxNumbers: [
          {
            type: '',
            value: ''
          }
        ],
        aes: 'AESX20220927123456'
      },
      trueShipper: false,
      consigneeDetails: {
        contactName: 'UK contact Name',
        companyName: 'UK company name',
        addressType: AddressType.BUSINESS,
        addressLine1: 'UK add 1',
        addressLine2: '',
        countyStateProvince: '',
        cityTown: '',
        postalCode: 'TQ2 6DD',
        telephoneNumber: '01803607158',
        email: 'c@c.com',
        sendEmail: false,
        taxNumbers: [
          {
            type: 'EORI',
            value: '3562524764'
          }
        ],
        additionalInfo: ''
      },
      brokerDetails: {
        brokerSelected: false,
        contactName: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        countyStateProvince: '',
        cityTown: '',
        country: {
          title: '',
          value: ''
        },
        postalCode: '',
        telephoneNumber: '',
        email: '',
        taxNumbers: [
          {
            type: '',
            value: ''
          }
        ]
      },
      preferences: {
        email: 'craig.williamson@johnmenzies.aero',
        labelType: 'PDF'
      },
      termsCheck: true,
      dangerousGoodsCheck: true,
      courierInfoCheck: true,
      specialService: [],
      customsDetails: {
        amount: {
          value: '',
          currency: ''
        },
        invoiceDetails: {
          declarationNotes: '',
          declarationStatementRequested: {
            value: false,
            type: ''
          },
          termsOfSale: 'DAP',
          taxDetails: [
            {
              type: '',
              party: '',
              value: ''
            }
          ],
          exportReason: '',
          specialInstructions: '',
          cpc: ''
        },
        electronicTradeDetails: {
          electronicTradeDocumentType: ElectronicTradeDocumentType.UNKNOWN,
          electronicTradeDocumentFormat: ElectronicTradeDocumentFormatType.JPEG,
          electronicTradeDocumentContent: '',
          electronicTradeDocumentName: ''
        },
        importerOfRecordDetails: {
          deliveryAddress: false,
          contactDetails: {
            name: '',
            telephone: ''
          },
          address: {
            organisation: '',
            countryCode: '',
            postalCode: '',
            streetLines: [
              '',
              ''
            ],
            city: '',
            stateOrCounty: ''
          }
        },
        commodities: [
          {
            commodityCode: '123456',
            description: 'Commod desc',
            countryOfOrigin: '',
            quantity: {
              value: '1',
              unit: 'PCS'
            },
            unitPrice: {
              value: '5000',
              currency: 'USD'
            },
            weight: {
              value: '10',
              unit: 'LB'
            }
          }
        ]
      },
      igBookingErrors: [],
      mappedPieces: [
        {
          dimensions: {
            length: 10,
            width: 10,
            height: 10,
            unit: 'IN'
          },
          weight: {
            value: 10,
            unit: 'LB'
          },
          description: ''
        }
      ],
      ddp: false
    }
  },  
  {
    templateName: 'Craig - US-FR non docs 3000 di',
    data: {
      origin: {
        title: 'United States',
        value: 'US'
      },
      originCityTown: 'seattle',
      originPostalCode: '98108',
      destination: {
        title: 'France',
        value: 'FR',
        tradeBloc: 'EUCU'
      },
      originResidential: false,
      destinationCityTown: 'paris',
      destinationPostalCode: '75009',
      destinationResidential: false,
      readyDate: 0,
      shipmentType: ShipmentType.NON_DOCS,
      pieces: [
        {
          weight: '10',
          length: '10',
          width: '10',
          height: '10'
        }
      ],
      imperialMetric: ImperialMetric.IMPERIAL,
      totalShipmentValue: '5000',
      preferredCurrency: {
        title: '$ USD',
        value: 'USD',
        symbol: '$'
      },
      quotes: [],
      selectedQuote: {
        carrierProductCode: '',
        carrierServiceName: '',
        charges: {
          currency: '',
          optional: [{
            currency: '',
            type: '',
            value: 0
          }],
          required: [{
            currency: '',
            type: '',
            value: 0
          }],
          value: 0,
        },
        deliveryDateEstimate: '',
        discount: {
          amount: {
            value: 0,
            currency: '',
          },
          rulesApplied: [],
        },
        quoteId: '',
        rate: {
          value: 0,
          currency: 0,
        },
        sameDayCollectionCutOffTime: 0,
        serviceType: '',
        totalCost: {
          value: 0,
          currency: '',
        },
        transitTimeEstimate: 0,
        weight: {
          actual: {
            unit: '',
            value: 0,
          },
          chargeable: {
            unit: '',
            value: 0,
          },
        }
      },
      insure: false,
      hawb: 'hawb4546',
      contentDescription: 'box of things',
      countryOfOrigin: {
        title: 'United States',
        value: 'US'
      },
      reasonForExport: ReasonForExport.SOLD,
      collectionDetails: {
        pickUpFrom: '',
        pickUpTo: '',
        contactName: '',
        companyName: '',
        addressType: AddressType.BUSINESS,
        packageLocation: PackageLocation.NONE,
        addressLine1: '',
        addressLine2: '',
        countyStateProvince: '',
        cityTown: '',
        postalCode: '',
        telephoneNumber: '',
        email: '',
        additionalInfo: ''
      },
      dropInDetails: {
        id: 56068,
        companyName: 'FedEx Drop Box',
        addressLine1: '1001 SW Klickitat Way',
        countyStateProvince: 'WA',
        cityTown: 'Seattle',
        postalCode: '98134',
        countryCode: 'US',
        telephoneNumber: ''
      },
      shipperDetails: {
        contactName: 'contact name',
        companyName: 'company name',
        addressLine1: 'ADD 1',
        addressLine2: 'ADD 2',
        countyStateProvince: 'WA',
        cityTown: 'Seattle',
        postalCode: '98108',
        country: {
          title: 'United States',
          value: 'US',
          tradeBloc: 'USMCA'
        },
        telephoneNumber: '0123456789',
        email: 'c@c.com',
        taxNumbers: [
          {
            type: '',
            value: ''
          }
        ],
        aes: 'AESX20220927123456'
      },
      trueShipper: false,
      consigneeDetails: {
        contactName: 'FR contact Name',
        companyName: 'FR company name',
        addressType: AddressType.BUSINESS,
        addressLine1: 'FR add 1',
        addressLine2: '',
        countyStateProvince: '',
        cityTown: '',
        postalCode: '75009',
        telephoneNumber: '01803607158',
        email: 'c@c.com',
        sendEmail: false,
        taxNumbers: [
          {
            type: 'EORI',
            value: '3562524764'
          }
        ],
        additionalInfo: ''
      },
      brokerDetails: {
        brokerSelected: false,
        contactName: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        countyStateProvince: '',
        cityTown: '',
        country: {
          title: '',
          value: ''
        },
        postalCode: '',
        telephoneNumber: '',
        email: '',
        taxNumbers: [
          {
            type: '',
            value: ''
          }
        ]
      },
      preferences: {
        email: 'craig.williamson@johnmenzies.aero',
        labelType: 'PDF'
      },
      termsCheck: true,
      dangerousGoodsCheck: true,
      courierInfoCheck: true,
      specialService: [],
      customsDetails: {
        amount: {
          value: '',
          currency: ''
        },
        invoiceDetails: {
          declarationNotes: '',
          declarationStatementRequested: {
            value: false,
            type: ''
          },
          termsOfSale: 'DAP',
          taxDetails: [
            {
              type: '',
              party: '',
              value: ''
            }
          ],
          exportReason: '',
          specialInstructions: '',
          cpc: ''
        },
        electronicTradeDetails: {
          electronicTradeDocumentType: ElectronicTradeDocumentType.UNKNOWN,
          electronicTradeDocumentFormat: ElectronicTradeDocumentFormatType.JPEG,
          electronicTradeDocumentContent: '',
          electronicTradeDocumentName: ''
        },
        importerOfRecordDetails: {
          deliveryAddress: false,
          contactDetails: {
            name: '',
            telephone: ''
          },
          address: {
            organisation: '',
            countryCode: '',
            postalCode: '',
            streetLines: [
              '',
              ''
            ],
            city: '',
            stateOrCounty: ''
          }
        },
        commodities: [
          {
            commodityCode: '123456',
            description: 'Commod desc',
            countryOfOrigin: '',
            quantity: {
              value: '1',
              unit: 'PCS'
            },
            unitPrice: {
              value: '5000',
              currency: 'USD'
            },
            weight: {
              value: '10',
              unit: 'LB'
            }
          }
        ]
      },
      igBookingErrors: [],
      mappedPieces: [
        {
          dimensions: {
            length: 10,
            width: 10,
            height: 10,
            unit: 'IN'
          },
          weight: {
            value: 10,
            unit: 'LB'
          },
          description: ''
        }
      ],
      ddp: false
    }
  },
  {
    templateName: 'Craig - UK-US non-docs 3000 Coll',
    data: {
      origin: {
        title: 'United Kingdom',
        value: 'GB',
        tradeBloc: ''
      },
      originCityTown: 'Edinburgh',
      originPostalCode: 'EH6 5BE',
      destination: {
        title: 'United States',
        value: 'US',
        tradeBloc: 'USMCA'
      },
      originResidential: false,
      destinationCityTown: 'Seattle',
      destinationPostalCode: '98108',
      destinationResidential: false,
      readyDate: 0,
      shipmentType: ShipmentType.NON_DOCS,
      pieces: [
        {
          weight: '10',
          length: '10',
          width: '10',
          height: '10'
        }
      ],
      imperialMetric: 'IMPERIAL',
      totalShipmentValue: '5000',
      preferredCurrency: {
        title: '$ USD',
        value: 'USD',
        symbol: '$'
      },
      quotes: [],
      selectedQuote: null,
      insure: false,
      hawb: 'hawb4546',
      contentDescription: 'box of things',
      countryOfOrigin: {
        title: 'United Kingdom',
        value: 'GB',
        tradeBloc: ''
      },
      reasonForExport: ReasonForExport.SOLD,
      collectionDetails: {
        pickUpFrom: '06:00:00',
        pickUpTo: '19:00:00',
        contactName: 'Contact Name',
        companyName: 'Company Name',
        addressType: AddressType.BUSINESS,
        packageLocation: PackageLocation.FRONT,
        addressLine1: 'Address 1',
        addressLine2: '',
        countyStateProvince: 'Mid Lothian',
        cityTown: 'Edinburgh',
        postalCode: 'EH6 5BE',
        telephoneNumber: '01315555555',
        email: 'test@test.com',
        additionalInfo: ''
      },
      dropInDetails: {
        id: '',
        openFrom: '',
        openTo: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        countyStateProvince: '',
        cityTown: '',
        postalCode: '',
        countryCode: '',
        telephoneNumber: ''
      },
      shipperDetails: {
        contactName: 'contact name',
        companyName: 'company name',
        addressLine1: '2f1, 282 Bonnington Road',
        addressLine2: 'ADD 2',
        countyStateProvince: 'Mid Lothian',
        cityTown: 'Edinburgh',
        postalCode: 'EH6 5BE',
        country: {
          title: 'United Kingdom',
          value: 'GB',
          tradeBloc: ''
        },
        telephoneNumber: '01311111111',
        email: 'c@c.com',
        taxNumbers: [
          {
            type: '',
            value: ''
          }
        ],
        aes: 'AESX20220927123456'
      },
      trueShipper: false,
      consigneeDetails: {
        contactName: 'US contact Name',
        companyName: 'US company name',
        addressType: AddressType.BUSINESS,
        addressLine1: 'US add 1',
        addressLine2: '',
        countyStateProvince: 'WA',
        cityTown: 'Seattle',
        postalCode: '98108',
        telephoneNumber: '6666644444',
        email: 'c@c.com',
        sendEmail: false,
        taxNumbers: [
          {
            type: '',
            value: ''
          }
        ],
        additionalInfo: ''
      },
      brokerDetails: {
        brokerSelected: false,
        contactName: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        countyStateProvince: '',
        cityTown: '',
        country: {
          title: '',
          value: ''
        },
        postalCode: '',
        telephoneNumber: '',
        email: '',
        taxNumbers: [
          {
            type: '',
            value: ''
          }
        ]
      },
      preferences: {
        email: 'craig.williamson@johnmenzies.aero',
        labelType: 'PDF'
      },
      termsCheck: true,
      dangerousGoodsCheck: true,
      courierInfoCheck: true,
      specialService: [],
      customsDetails: {
        amount: {
          value: '',
          currency: ''
        },
        invoiceDetails: {
          declarationNotes: '',
          declarationStatementRequested: {
            value: false,
            type: ''
          },
          termsOfSale: 'DAP',
          taxDetails: [
            {
              type: '',
              party: '',
              value: ''
            }
          ],
          exportReason: '',
          specialInstructions: '',
          cpc: ''
        },
        electronicTradeDetails: {
          electronicTradeDocumentType: ElectronicTradeDocumentType.UNKNOWN,
          electronicTradeDocumentFormat: ElectronicTradeDocumentFormatType.JPEG,
          electronicTradeDocumentContent: '',
          electronicTradeDocumentName: ''
        },
        importerOfRecordDetails: {
          deliveryAddress: false,
          contactDetails: {
            name: '',
            telephone: ''
          },
          address: {
            organisation: '',
            countryCode: '',
            postalCode: '',
            streetLines: [
              '',
              ''
            ],
            city: '',
            stateOrCounty: ''
          }
        },
        commodities: [
          {
            commodityCode: '123456',
            description: 'Commod desc',
            countryOfOrigin: '',
            quantity: {
              value: '1',
              unit: 'PCS'
            },
            unitPrice: {
              value: '5000',
              currency: 'USD'
            },
            weight: {
              value: '10',
              unit: 'LB'
            }
          }
        ]
      },
      igBookingErrors: [],
      mappedPieces: [
        {
          dimensions: {
            length: 10,
            width: 10,
            height: 10,
            unit: 'IN'
          },
          weight: {
            value: 10,
            unit: 'LB'
          },
          description: ''
        }
      ],
      ddp: false
    }
  },
  {
    templateName: 'Max - CA-UK non-docs 1500 single-piece',
    data: {
      origin: {
        title: "Canada",
        value: "CA",
        tradeBloc: "USMCA"
      },
      originCityTown: "Toronto",
      originPostalCode: "M3C 1T3",
      destination: {
        title: "United Kingdom",
        value: "GB",
        tradeBloc: ""
      },
      originResidential: false,
      destinationCityTown: "Chichester",
      destinationPostalCode: "PO18 0AB",
      destinationResidential: false,
      readyDate: dayjs().unix() * 1000,
      shipmentType: "NON_DOCS",
      pieces: [
        {
          weight: "10",
          length: "10",
          width: "10",
          height: "10"
        }
      ],
      imperialMetric: "IMPERIAL",
      totalShipmentValue: "2000",
      preferredCurrency: {
        title: '$ USD',
        value: 'USD',
        symbol: '$'
      },
      quotes: [],
      selectedQuote: {
        quoteId: "dhl-c5587c17-7b29-4dcf-bfcc-51ab79479a06",
        carrierServiceName: "DHL-US-CXT-ND-COL",
        carrierProductCode: "EXPRESS WORLDWIDE NONDOC",
        serviceType: "COLLECTED",
        weight: {
          chargeable: {
            value: 9.9,
            unit: "LB"
          },
          actual: {
            value: 10,
            unit: "LB"
          }
        },
        transitTimeEstimate: "5",
        deliveryDateEstimate: "2022-10-19T23:59:00",
        sameDayCollectionCutOffTime: "16:30:00",
        discount: {
          amount: {
            value: 0,
            currency: "USD"
          },
          rulesApplied: []
        },
        charges: {
          value: 77.25,
          currency: "USD",
          required: [
            {
              value: 0.9,
              currency: "USD",
              type: "EMERGENCY_SITUATION"
            },
            {
              value: 76.35,
              currency: "USD",
              type: "FUEL"
            }
          ],
          optional: [
            {
              value: 20,
              currency: "USD",
              type: "DELIVERED_DUTY_PAID"
            }
          ]
        },
        totalCost: {
          value: 469.19,
          currency: "USD"
        },
        rate: {
          value: 391.94,
          currency: "USD"
        }
      },
      insure: false,
      hawb: "PAD234567",
      contentDescription: "Shoes",
      countryOfOrigin: {
        title: "Canada",
        value: "CA",
        tradeBloc: "USMCA"
      },
      reasonForExport: "SOLD",
      collectionDetails: {
        pickUpFrom: "09:00:00",
        pickUpTo: "14:30:00",
        contactName: "Henri Lebaux",
        companyName: "Citroen",
        addressType: 1,
        packageLocation: "FRONT",
        addressLine1: "500 Ave Lacroix",
        addressLine2: "",
        countyStateProvince: "ON",
        cityTown: "",
        postalCode: 'M3C1T3',
        telephoneNumber: "0790021230",
        email: "h.lebaux@citroen.org",
        additionalInfo: ""
      },
      dropInDetails: {
        id: "",
        openFrom: "",
        openTo: "",
        companyName: "",
        addressLine1: "",
        addressLine2: "",
        countyStateProvince: "",
        cityTown: "",
        postalCode: "",
        countryCode: "",
        telephoneNumber: ""
      },
      shipperDetails: {
        contactName: "Henri Lebaux",
        companyName: "Citroen",
        addressLine1: "500 Ave Lacroix",
        addressLine2: "",
        countyStateProvince: "ON",
        cityTown: "Toronto",
        postalCode: "M3C1T3",
        country: {
          title: "Canada",
          value: "CA",
          tradeBloc: "USMCA"
        },
        telephoneNumber: "0790021230",
        email: "h.lebaux@citroen.org",
        taxNumbers: [
          {
            type: "",
            value: ""
          }
        ],
        aes: "",
        b13AFilingOption: "NOT_REQUIRED",
        pickUpFrom: "09:00:00",
        pickUpTo: "14:30:00",
        addressType: 1,
        packageLocation: "FRONT",
        additionalInfo: ""
      },
      trueShipper: true,
      consigneeDetails: {
        contactName: "Stanley Knife",
        companyName: "Tools R Us",
        addressType: 1,
        addressLine1: "2 Other Street",
        addressLine2: "",
        countyStateProvince: "West Sussex",
        cityTown: "Chichester",
        postalCode: 'PO18 0AB',
        telephoneNumber: "9876543210",
        email: "stan@toolsrus.co.uk",
        sendEmail: false,
        taxNumbers: [
          {
            type: "",
            value: ""
          }
        ],
        additionalInfo: ""
      },
      brokerDetails: {
        brokerSelected: false,
        contactName: "",
        companyName: "",
        addressLine1: "",
        addressLine2: "",
        countyStateProvince: "",
        cityTown: "",
        country: {
          title: "",
          value: ""
        },
        postalCode: "",
        telephoneNumber: "",
        email: "",
        taxNumbers: [
          {
            type: "",
            value: ""
          }
        ]
      },
      invoice: {
        ownOrGenerate: "OWN_INVOICE",
        attachOrUpload: "ATTACH_TO_SHIPMENT",
        attachOrSubmit: "DOWNLOAD_AND_ATTACH",
        number: "",
        date: "",
        name: "",
        position: ""
      },
      preferences: {
        email: "max.robson@airmenzies.com",
        labelType: "PDF"
      },
      termsCheck: false,
      dangerousGoodsCheck: false,
      courierInfoCheck: false,
      specialService: "UNKNOWN",
      customsDetails: {
        amount: {
          value: "",
          currency: ""
        },
        invoiceDetails: {
          declarationNotes: "",
          termsOfSale: "DAP",
          taxDetails: [
            {
              type: "",
              party: "",
              value: ""
            }
          ],
          exportReason: "",
          specialInstructions: "",
          cpc: ''
        },
        electronicTradeDetails: {
          electronicTradeDocumentType: "UNKNOWN",
          electronicTradeDocumentFormat: "JPEG",
          electronicTradeDocumentContent: "",
          electronicTradeDocumentName: ""
        },
        importerOfRecordDetails: {
          deliveryAddress: false,
          contactDetails: {
            name: "",
            telephone: ""
          },
          address: {
            organisation: "",
            countryCode: "",
            postalCode: "",
            streetLines: [
              "",
              ""
            ],
            city: "",
            stateOrCounty: ""
          }
        },
        commodities: [
          {
            commodityCode: "6402",
            description: "Shoes",
            countryOfOrigin: "",
            quantity: {
              value: "1",
              unit: "PCS"
            },
            unitPrice: {
              value: "2000",
              currency: "USD"
            },
            weight: {
              value: "10",
              unit: "LB"
            }
          }
        ]
      },
      igBookingErrors: [],
      mappedPieces: [
        {
          dimensions: {
            length: 10,
            width: 10,
            height: 10,
            unit: "IN"
          },
          weight: {
            value: 10,
            unit: "LB"
          },
          description: ""
        }
      ],
      ddp: false
    }
  }
]