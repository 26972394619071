import React, { useEffect, useState } from 'react';
import './Quote.scss';
import { Booking, QuoteObj, TermsOfSale, TransportMethod, bookingActions } from '../../../../features/bookingSlice';
import { AMIPopover, Icon } from '../../../../ui-components/UiComponents';
import { OptionalCharge, calculateBasePrice, calculateBookingTotalCost, getCarrierName, getECSData, getOptionalChargeCost, removeDigitsAndSymbolsFromString, snakeCaseConvertor } from '../../../../utilities/HelperUtilities';
import { calculateQuoteCost, showTime } from '../utilities';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import { PageStatus, appDataActions } from '../../../../features/appDataSlice';
import { useHistory } from 'react-router-dom';
import { TradeRoute, getTradeRoute } from '../../../../utilities/RulesEngineUtilities';

const Quote: React.FC<{
  quote: QuoteObj
  insured: boolean
  ddpSelected: boolean
  insuranceCost: number
  showServiceType: boolean
  booking: Booking
}> = ({
  quote,
  insured,
  ddpSelected,
  insuranceCost,
  showServiceType,
  booking
}) => {

  const dispatch = useAppDispatch();
  const customer = useAppSelector(customerDetailsSelector);
  const ddpCost = getOptionalChargeCost(quote, OptionalCharge.DELIVERED_DUTY_PAID);
  const currencySymbol = customer.countryOfResidence.currencySymbol;
  const history = useHistory();

  const [isRestrictionsPopoverVisible, setIsRestrictionsPopoverVisible] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState('');
  const [quoteCost, setQuoteCost] = useState<any>();
  const [ecsData, setECSData] = useState<any>({type: "", amount: ""});
  const [basePrice, setBasePrice] = useState<number>(0);

  useEffect(() => {
    getAndSetDeliveryDate();
    setECSData(getECSData(booking));
    setBasePrice(calculateBasePrice(customer, quote))
  }, [])

  useEffect(() => {
    const totalCost = calculateQuoteCost(
      quote,
      booking,
      customer,
      insured,
      insuranceCost,
      ddpSelected,
      ddpCost
    );

    setQuoteCost(currencySymbol + totalCost);
  }, [insured, ddpSelected]);

  const getAndSetDeliveryDate = () => {
    const SUNDAY = 0;
    const SATURDAY = 6
    let deliveryDate = dayjs(quote.deliveryDateEstimate);

    if (deliveryDate.day() === SATURDAY) deliveryDate = deliveryDate.add(2, 'day');
    if (deliveryDate.day() === SUNDAY) deliveryDate = deliveryDate.add(1, 'day');

    setDeliveryDate(deliveryDate.format("ddd DD MMM"));
  }

  const formatTime = () => {
    if (showTime(quote)) return dayjs(quote.deliveryDateEstimate).format('hh:mmA')
    else return ''
  }

  const setCollectionTimes = (quote: QuoteObj) => {
    if (quote.pickupEarliestTime) {
      dispatch(bookingActions.updateCollectionDetail({
        field: 'pickUpFrom',
        value: quote.pickupEarliestTime
      }))
    }

    if (quote.pickupLatestTime) {
      dispatch(bookingActions.updateCollectionDetail({
        field: 'pickUpTo',
        value: quote.pickupLatestTime
      }))
    }
  }

  const onSelect = () => {
    if (customer.creditCheck.credit) {
      dispatch(bookingActions.setSelectedQuote(quote));
      dispatch(bookingActions.updateField({
        field: 'insure',
        value: insured
      }));
      dispatch(bookingActions.updateTermsOfSale(ddpSelected ? TermsOfSale.DDP : TermsOfSale.DAP));
      setCollectionTimes(quote);
      dispatch(appDataActions.setQuotePageStatus(PageStatus.COMPLETE));
      history.push('/details');
    }
  };

  return (
    <div
      className="quote"
      onClick={onSelect}
    >
      <div className="quote__details">

        <strong>{removeDigitsAndSymbolsFromString(snakeCaseConvertor(quote.carrierProductCode))}</strong>

        {showTime(quote) && <p> Estimated Delivery by {deliveryDate} {formatTime()}</p>}
        {!showTime(quote) && <p> Estimated Delivery on {deliveryDate}</p>}

        {showServiceType && <div className="quote__details__service-type">{snakeCaseConvertor(quote.serviceType)}</div>}

        {quote.transportMethod === TransportMethod.EXPRESS && (
          <div className="quote__details__transport-method">
            <Icon
              name="Express"
              style={{
                width: "24px"
              }}
            />
            Express
          </div>
        )}

        {quote.transportMethod === TransportMethod.DOMESTIC && (
          <div className="quote__details__transport-method">
            <Icon
              name="Truck"
              style={{
                width: "24px"
              }}
            />
            Domestic
          </div>
        )}

        {quote.transportMethod === TransportMethod.ROAD && (
          <div className="quote__details__transport-method">
            <Icon
              name="Truck"
              style={{
                width: "24px"
              }}
            />
            Road
            {getCarrierName(quote.quoteId) === "dhl" && (
              <span
                onMouseEnter={() => setIsRestrictionsPopoverVisible(true)}
                onMouseLeave={() => setIsRestrictionsPopoverVisible(false)}
              > <strong>(Restrictions
                <Icon
                  name="CircleQuestion"
                  color="var(--primary)"
                />
              )</strong>
                <AMIPopover isVisible={isRestrictionsPopoverVisible}>
                  Please note following prohibitions applicable only to road (ESI product) traffic: goods subject to excise taxes (tobacco, alcoholic beverages) & goods subject to government controls (drugs, medical devices and sanitary products, cosmetics, skincare and hygiene products, goods subject to phytosanitary or veterinary border controls, products for human consumption of all types, even being of non-animal origin, animal feeds.
                </AMIPopover>
              </span>
            )}
          </div>
        )}
      </div>

      <div className="quote__price">
        <p className="quote__price__total">{quoteCost}</p>
        <div className="quote__price__breakdown">
          <p>Base Price:</p>
          <p>{currencySymbol}{basePrice.toFixed(2)}</p>
          {quote.charges.required?.map((charge: any, index: number) => {
            return (
              <>
                <p key={charge.type + index}>{snakeCaseConvertor(charge.type)}:</p>
                <p key={charge.value + index}>{currencySymbol}{charge.value.toFixed(2)}</p>
              </>
            )
          })}

          {insured && (
            <>
              <p>Insurance:</p>
              <p>{currencySymbol}{insuranceCost}</p>
            </>
          )}

          {ddpSelected && (
            <>
              <p>DDP:</p>
              <p>{currencySymbol}{ddpCost}</p>
            </>
          )}

          {booking.shipperDetails.generateECS && (
            <>
              <p>{ecsData.type}</p>
              <p>{currencySymbol}{ecsData.amount}</p>
            </>
          )}

          {getTradeRoute(customer, booking) === TradeRoute.DOMESTIC && booking.origin.value === 'AU' && quote.vat.value && (
            <>
              <p>GST:</p>
              <p>{currencySymbol}{quote.vat.value}</p>
            </>
          )}

          {quote.discount.amount.value !== 0
            && customer.countryOfResidence.value !== "GB"
            && (
              <>
                <p>Discount:</p>
                <p>{currencySymbol}{quote.discount.amount.value.toFixed(2)}</p>
              </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Quote;